import React from "react";
import _string from "../../../config/localization/strLng";

function Credits(): JSX.Element {
  return (
    <div className="outer-container fluid bg-black text-sm">
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center md:items-center inner-container p-3 md:p-6 text-white">
        <span className="font-light mb-2 md:mb-0">
          {_string.STRINGS.footer_credits}
        </span>
        <div className="">
          <span className="mr-2">{_string.STRINGS.powered_by}</span>
          <button className="text-primary-700 hover:text-primary-900 transition duration-100 ease-linear">
            {_string.STRINGS.landmark}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Credits;
