import React from "react";
import { AddressConfig } from "../../../Types";

interface FooterAddressProps {
  address: AddressConfig;
}

function FooterAddress({ address }: FooterAddressProps): JSX.Element {
  return (
    <div className="flex flex-col text-white p-8">
      <span className="mb-3 text-lg lg:text-xl uppercase">{address.label}</span>

      <Item
        iconClass="las la-map-marker"
        labels={[address.location]}
        type="location"
      />
      <Item
        iconClass="las la-phone"
        labels={address.phoneNumber}
        type="phone"
      />
      <Item iconClass="las la-envelope" labels={address.email} type="mail" />
    </div>
  );
}

export default FooterAddress;

interface ItemProps {
  iconClass: string;
  labels: string[];
  type: "location" | "mail" | "phone";
}

function Item({ iconClass, labels, type }: ItemProps) {
  return (
    <div className="flex items-center my-2 font-light">
      <div className="flex items-center justify-center w-6 h-6 rounded-full bg-white mr-4 ">
        <i className={`${iconClass} text-grayMatte`} />
      </div>
      {type === "location" &&
        labels.map((label, index) => <span key={index}>{label}</span>)}
      {type === "phone" && (
        <div className="flex flex-wrap">
          {labels.map((label, index) => (
            <a
              key={index}
              className="hover:text-primary-600 mr-2 transition duration-100 ease-linear"
              href={`tel:${label}`}
            >
              {label}
            </a>
          ))}
        </div>
      )}
      {type === "mail" && (
        <div className="flex flex-wrap">
          {labels.map((label, index) => (
            <a
              key={index}
              className="hover:text-primary-600 mr-2 transition duration-100 ease-linear"
              href={`mailto:${label}`}
            >
              {label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
