import React, { useContext } from "react";
import { ProfileContext } from "./ProfileProvider";
import home from "../home";
import comp from "./components";
import contact from "../contact";
import _string from "../../config/localization/strLng";

function Layout(): JSX.Element {
  const {
    transactions,
    isLoading,
    bestSellingItems,
    transactionsPagination,
    setTransactionsPagination,
  } = useContext(ProfileContext);
  return (
    <div className={"outer-container"}>
      <div className="relative inner-container flex flex-wrap pt-12 lg:pt-0 py-10 lg:py-12 lg:pr-16">
        <div className="flex w-full lg:w-9/12 py-4 lg:py-12 px-4">
          <comp.BestSellingItems items={bestSellingItems} />
        </div>
        <div className="flex w-full lg:w-3/12 py-4 lg:py-12 px-4">
          <comp.SideNotifications />
        </div>
        <div className="flex flex-col w-full lg:w-9/12 py-4 lg:py-12 px-4">
          <home.components.PriceTable
            hideSideLabel
            noFilters
            isLoading={isLoading}
            header={
              <div className="flex justify-between items-center py-4 px-10 rounded-sm lg:rounded-none bg-green-700">
                <h4 className="font-bold uppercase flex-1 text-center text-white">
                  {_string.LABELS.transactions}
                </h4>
              </div>
            }
            config={{
              headLabels: [
                _string.LABELS.metals,
                _string.LABELS.total,
                _string.LABELS.updated_at,
              ],
              data: [...transactions],
            }}
            pagination={transactionsPagination}
            setPagination={setTransactionsPagination}
          />
        </div>

        <contact.components.AsideContact height={850} />
      </div>
    </div>
  );
}

export default Layout;
