import { AxiosError, AxiosResponse } from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { fetchApi } from "../../config/core/Api";
import _string from "../../config/localization/strLng";
import { GeneralLayout } from "../../layout";
import { AppContext } from "../../provider";
import { MetalsResponse } from "../../Types";
import home from "./";
import { formateDate } from "./components/ProductPrices";
import { TableDataTypes } from "../home/components/ProductPrices";

function HomeScreen(): JSX.Element {
  const { notify } = useContext(AppContext);

  const [products, setProducts] = useState<TableDataTypes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getProducts = useCallback(() => {
    setIsLoading(true);
    fetchApi({
      url: "/metals",
      method: "GET",
    })
      .then((r: AxiosResponse<MetalsResponse>) => {
        let tempProducts: TableDataTypes[] = [];
        r.data.data.forEach((metal) => {
          tempProducts.push({
            price: {
              headLabel: _string.LABELS.price,
              data: `${metal.price} ALL`,
            },
            product: {
              headLabel: _string.LABELS.metals,
              data: metal.display_name,
            },
            updated_at: {
              headLabel: _string.LABELS.updated_at,
              data: formateDate(new Date(metal.updated_at)),
            },
          });
        });
        setProducts(tempProducts);
      })
      .catch((e: AxiosError) => {
        notify(_string.ERRORS.something_went_wrong, "error");
        console.log(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [notify]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <GeneralLayout pageName={_string.PAGES.home}>
      <>
        <home.components.Slider />
        <home.components.ServicesSteps />
        <home.components.ActionCards />
        <home.components.Industry_Marketing />
        <home.components.ProductPrices
          config={{
            headLabels: [
              _string.LABELS.metals,
              _string.LABELS.price,
              _string.LABELS.updated_at,
            ],
            data: products,
          }}
          noFilters={true}
          isLoading={isLoading}
        />
        <home.components.CollectionProcess />
        {/* <home.components.ContactBanner /> */}
      </>
    </GeneralLayout>
  );
}

export default HomeScreen;
