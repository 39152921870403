import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/img/logo_main.png";
import { UserContext } from "../../provider";
import navigation from "./";
import { pages } from "./common-routes";
import global from "../../global";

export interface MenuPages {
  label: string;
  page: string;
}

function HomeNavigation(): JSX.Element {
  let history = useHistory();
  const { userInfo } = useContext(UserContext);

  return (
    <>
      <navigation.MobileNavigation />
      <div className="outer-container fluid bg-primary-800 shadow-md">
        <div className="inner-container flex justify-between items-center bg-white">
          <div className="flex justify-center items-center">
            <global.Image
              className="cursor-pointer h-16 lg:h-24"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  window.scrollTo(0, 1);
                }, 100);
              }}
              src={logo}
              alt=""
            />
          </div>
          <div className="flex justify-center items-center h-full text-lg">
            <div className="hidden lg:flex justify-center items-center h-full">
              {pages.map((page, index) => (
                <Button
                  key={index}
                  label={page.label}
                  page={page.page}
                  isActive={
                    history.location.pathname === page.page.split("#")[0]
                  }
                />
              ))}
            </div>
            {userInfo ? (
              <navigation.HomeActions />
            ) : (
              <navigation.GuestActions />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeNavigation;

interface ButtonProps extends MenuPages {
  isActive: boolean;
}

function Button({ label, page, isActive }: ButtonProps) {
  let history = useHistory();
  return (
    <button
      className={`
      ${isActive && "text-primary-900 underline"}
      flex items-center justify-center h-full
      text-black uppercase py-2 px-6 hover:bg-primary-100 transition duration-100 ease-linear`}
      onClick={() => {
        history.push(page);
        setTimeout(() => {
          window.scrollTo(0, 1);
        }, 100);
      }}
    >
      <span>{label}</span>
    </button>
  );
}
