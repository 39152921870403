import { HomeInfoConfig } from "../Types";
import slide_home_1 from "../assets/img/slide_home_1.png";
import slide_home_2 from "../assets/img/slide_home_2.png";
import slide_home_3 from "../assets/img/slide_home_3.png";
import * as collection from "../assets/img/collection";
import * as products from "../assets/img/products";
import * as thirds from "../assets/img/thirds";

export const homeInfoConfig_Al: HomeInfoConfig = {
  servicesSteps: [
    {
      number: "01",
      title: "Grumbullimi",
      description:
        "Çekrezi Metal ka kapacitete të mëdha magazinuese për grumbullimin e mbetjeve të ndryshme metalike si skrap, bakër, Bronx, alumin, dural, bateri,kabëll, tela, inoks, kanace, plumb, zink, sponde, radiator, gizë dhe automjete të vjetra të dala jashtëfunksionit. Gjithashtu disponon edhe makineritë dhe pajisjet për presimin, përpunimin dhe ambalazhimin e këtyre mbetjeve metalike duke bërë kështu të mundur plotësimin e kërkesave të klientëve të kësaj industrie.",
      linkLabel: "Metalet të cilat grumbullojmë",
      linkHref: "/services",
      sectionId: "#collection",
      subItem: [
        {
          label: "skrap",
          photoUrl: collection.Skrap,
        },
        {
          label: "bakër",
          photoUrl: collection.Baker,
        },
        {
          label: "bronx",
          photoUrl: collection.Bronx,
        },
        {
          label: "alumin",
          photoUrl: collection.Alumin,
        },
        {
          label: "dural",
          photoUrl: collection.Dural,
        },
        {
          label: "bateri",
          photoUrl: collection.Bateri,
        },
        {
          label: "kabëll",
          photoUrl: collection.Kabell,
        },
        {
          label: "tela",
          photoUrl: collection.Tela,
        },
        {
          label: "inoks",
          photoUrl: collection.Inoks,
        },
        {
          label: "kanace",
          photoUrl: collection.Kanace,
        },
        {
          label: "plumb",
          photoUrl: collection.Plumb,
        },
        {
          label: "zink",
          photoUrl: collection.Zink,
        },
        {
          label: "sponde",
          photoUrl: collection.Sponde,
        },
        {
          label: "radiatorë",
          photoUrl: collection.Radiatore,
        },
        {
          label: "gizë",
          photoUrl: collection.Gize,
        },
        {
          label: "automjete të vjetra",
          photoUrl: collection.Automjete,
        },
        {
          label: "karton, plastik dhe qelq",
          photoUrl: collection.GrumbullimKartonPalstikQelq,
        },
        {
          label: "tregtim hekur ndërtimi",
          photoUrl: collection.TregetimHekurNdertimi,
        },
      ],
    },
    {
      number: "02",
      title: "PRODUKTE",
      description:
        "Tek Çekrezi Metal do të gjeni pjesë këmbimi, profile të përmasave të ndryshme,llamarina, lingota hekuri dhe alumini, ele dhe shumë produkte të tjera të grumbulluara. Meqënëse grumbullimi i skrap dhe i metaleve është biznesi ynë në japim një kontribut të rëndësishëm në mbrojtjen e ambjentit, por në të njëjten kohë tek ne mund të gjeni produkte për ndërtim, rikonstruktim si dhe pjesë për makineritë dhe automjetet tuaja.",
      linkLabel: "Produktet të cilat ofrojmë",
      linkHref: "/services",
      sectionId: "#products",
      subItem: [
        {
          label: "pjesë këmbimi",
          photoUrl: products.Pjese,
        },
        {
          label: "profile",
          photoUrl: products.Profile,
        },
        {
          label: "llamarina",
          photoUrl: products.Llamarina,
        },
        {
          label: "lingota hekuri/alumini",
          photoUrl: products.Lingota,
        },
        {
          label: "ele",
          photoUrl: products.Ele,
        },
      ],
    },
    {
      number: "03",
      title: "PËR TË TRETË",
      description:
        "Çekrezi Metal vjen në ndihmë të kompanive me shërbime si: peshore 70 ton, kamion, rimorkiator dhe takraf. Korrektësia dhe eksperienca prej shumë vitesh e kompanisë sonë në këtë sektor ka sjelle besim dhe respekt të klientëve tanë.Stafi I profesionistëve tanë është gjithmonë I gatshëm për tju ardhur në ndihmë me zgjidhje sa më optimale. Nëse kërkoni një partner për biznesin tuaj zgjedhja është vetëm Çekrezi Metal.",
      linkLabel: "Shërbime për palët e treta",
      linkHref: "/services",
      sectionId: "#third-parties",
      subItem: [
        {
          label: "peshore 70 ton",
          photoUrl: thirds.Peshore,
        },
        {
          label: "kamion",
          photoUrl: thirds.Kamion,
        },
        {
          label: "trailer",
          photoUrl: thirds.Trailer,
        },
        {
          label: "rimorkiator",
          photoUrl: thirds.Rimorkiator,
        },
        {
          label: "takraf",
          photoUrl: thirds.MetalFotoPershkrimi,
        },
      ],
    },
  ],
  mainSliderImages: [slide_home_1, slide_home_2, slide_home_3],
  contactBannerImage: slide_home_1,
  industry_marketing: {
    title: "Pozicioni i Çekrezi metal në industri",
    description:
      "Zhvillimi ekonomik dhe social në Shqipëri gjatë dekadës së fundit ka sjellë rritje të industrisë së riciklimit. Kjo situatë ka bërë të mundur organizimin e sistemit, me të paktën katër faza të procesit industrial të riciklimit. Vend të rëndësishëm zenë metalet me ngjyrë (alumin, bakër, bronx, zink, plumb) dhe metalet derozë (hekur,celik). Skema e thjeshtuar e fazave të procesit në industrinë e grumbullimit dhe riciklimit është e përbërë nga: kolektorët individual, pikat e vogla grumbulluese, qëndrat e mëdha grumbulluese dhe kompanitë ricikluese ose eksportuese.",
    image: slide_home_2,
  },
  collectionProcess: {
    title:
      "Skema e fazave tË proÇesit industrial tË grumbullimit dhe reciklimit",
    description:
      "Qëndrat e mëdha të grumbullimit të materialeve të riciklueshme janë kompani private të shpërndara në të gjithë territorin e Shqipërisë. Njëra nga këto qëndra është Çekrezi Metal, e cila ka mjetet dhe pajisjet baze që nevojiten për grumbullim, kategorizim, prerje, përpunim, paketim, ngarkim, shkarkimin dhe transportin e këtyre materialeve. Çmimet më të mira në treg, kushtet e favorshme të grumbullimit, shërbimi profesional dhe asesibiliteti me pikat e grumbullimit dhe kolektorët individual e bëjnë Çekrezi Metal një nga kompanitë më të rëndësishme në industrinë e grumbullimit dhe reciklimit të metaleve.",
  },
};
