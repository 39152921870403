import React from "react";
import { homeInfoConfig_Al } from "../../../config/HomeInfoConfig";
import home from "../.";

function ServicesSteps(): JSX.Element {
  return (
    <div className="outer-container">
      <div className="flex flex-col flex-wrap items-center xl:items-start xl:flex-row inner-container pt-12 xl:py-32">
        {homeInfoConfig_Al &&
          homeInfoConfig_Al.servicesSteps.map((service, index) => (
            <div
              key={index}
              className="w-full h-auto xl:h-full xl:w-4/12 p-4 xl:px-12 mb-12 xl:mb-0"
              style={{ maxWidth: 800 }}
            >
              <home.components.ServiceItem {...service} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ServicesSteps;
