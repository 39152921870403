import React from "react";

interface InputProps {
  error?: string;
  placeholder: string;
  type: "text" | "number" | "email" | "textarea";
}

function Input({ error, ...props }: InputProps) {
  return (
    <div className="flex flex-col flex-1 relative pb-5 text-white">
      {props.type === "textarea" ? (
        <textarea
          style={{ minHeight: 200 }}
          className="flex-1 placeholder-gray-300 border border-gray-300 rounded px-6 py-6 bg-transparent mb-2"
          {...props}
        ></textarea>
      ) : (
        <input
          {...props}
          className="flex-1 placeholder-gray-300 border-b border-gray-300 px-2 py-2 bg-transparent mb-2"
        />
      )}
      {error && (
        <span className="absolute left-0 bottom-0 w-full truncate text-red-600 px-2 pt-1">
          {error}
        </span>
      )}
    </div>
  );
}

export default Input;
