import React, { useContext } from "react";
import _string from "../../config/localization/strLng";
import { GeneralLayout } from "../../layout";
import service from "../service";
import contact from "../contact";
import { AppContext } from "../../provider";

function ServicesScreen(): JSX.Element {
  const { isXl } = useContext(AppContext);
  return (
    <GeneralLayout pageName={_string.PAGES.services}>
      <div className="outer-container">
        <div className="inner-container relative flex flex-wrap py-8 lg:py-16 xl:py-32 lg:px-6">
          <div className="flex flex-col w-full xl:w-5/12 lg:flex-row">
            <service.components.ServiceSteps />
          </div>
          {!isXl && (
            <div className="flex w-full flex-col xl:w-7/12 px-5 lg:px-20 services">
              <service.components.ProductList />
            </div>
          )}

          <contact.components.AsideContact height={850} />
        </div>
      </div>
    </GeneralLayout>
  );
}

export default ServicesScreen;
