import moment from "moment";
import React, { Dispatch } from "react";
import { v4 as uuid } from "uuid";
import _string from "../../../config/localization/strLng";
import global from "../../../global";
import { PaginationPaths } from "../../../Types";

export interface TableTypes {
  headLabels: string[];
  data: TableDataTypes[];
}

export interface TableDataTypes {
  price: TableDataInnerTypes;
  product: TableDataInnerTypes;
  updated_at: TableDataInnerTypes;
}

export interface TableDataInnerTypes {
  headLabel: string;
  data: string;
  increasing?: boolean;
}

interface ProductPricesTypes extends PriceTableTypes {}

function ProductPrices({ ...props }: ProductPricesTypes): JSX.Element {
  return (
    <div className="outer-container">
      <div className="inner-container flex flex-col items-center px-4 relative overflow-hidden py-6 md:py-12">
        <PriceTable {...props} />
      </div>
    </div>
  );
}

export default ProductPrices;

interface PriceTableTypes {
  config: TableTypes;
  hideSideLabel?: boolean;
  header?: JSX.Element | null;
  noFilters?: boolean;
  isLoading?: boolean;
  pagination?: PaginationPaths;
  setPagination?: Dispatch<PaginationPaths>;
}

export function PriceTable({
  config,
  hideSideLabel,
  header = null,
  noFilters = false,
  isLoading = false,
  pagination,
  setPagination,
}: PriceTableTypes) {
  return (
    <>
      <div
        className="flex flex-col w-full text-base md:text-lg z-10"
        style={{ maxWidth: 1200 }}
      >
        {!noFilters && (
          <div className="flex justify-between w-full mb-4 md:mb-10">
            <OutlinedBtn>
              <i className="las la-search"></i>
            </OutlinedBtn>
            <div className="flex">
              <OutlinedBtn className="mr-2 px-3">
                <div className="flex items-center">
                  <span className="text-xs md:text-base mr-10">Filter</span>
                  <i className="las la-angle-down text-2xs"></i>
                </div>
              </OutlinedBtn>

              <OutlinedBtn className="mr-2">
                <i className="las la-angle-left"></i>
              </OutlinedBtn>

              <OutlinedBtn>
                <i className="las la-angle-right"></i>
              </OutlinedBtn>
            </div>
          </div>
        )}
        {/* Table Header */}
        {header && header}
        {/* End Table Header */}

        {/* Rows */}

        <div className="hidden md:flex justify-between items-center py-4 px-10 border border-solid border-gray-300 bg-white">
          {config.headLabels.map((label, index) => {
            return (
              <h4
                key={uuid()}
                className={`font-bold uppercase flex-1 ${
                  index === 0
                    ? "text-left"
                    : index !== 0 && index !== config.headLabels.length - 1
                    ? "text-center"
                    : index === config.headLabels.length - 1
                    ? "text-right"
                    : ""
                }`}
              >
                {label}
              </h4>
            );
          })}
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center p-5 border-gray-300 border-solid border-l border-r border-b">
            <div className="">
              <i className="text-3xl las la-circle-notch animate-spin"></i>
            </div>
          </div>
        ) : config.data.length === 0 ? (
          <RowItem
            key={uuid()}
            price={{
              headLabel: _string.LABELS.total,
              data: "-",
              increasing: true,
            }}
            product={{
              headLabel: _string.LABELS.metals,
              data: "-",
            }}
            updated_at={{
              headLabel: _string.LABELS.updated_at,
              data: "-",
            }}
          />
        ) : (
          config.data.map((item) => (
            <RowItem
              key={uuid()}
              price={{ ...item.price }}
              product={{ ...item.product }}
              updated_at={{ ...item.updated_at }}
            />
          ))
        )}

        {pagination && setPagination && (
          <global.PaginationArrow
            pagination={pagination}
            setPagination={setPagination}
          />
        )}

        {/* End Rows */}
      </div>

      {!hideSideLabel && (
        <span
          style={{
            transform: `rotate(-90deg) translateX(35%)`,
            left: -20,
            top: `50%`,
            zIndex: 0,
            color: "#1A1A1A",
            opacity: 0.05,
          }}
          className="absolute hidden md:block uppercase text-5xl font-bold font-candal"
        >
          {_string.LABELS.metals}
        </span>
      )}
    </>
  );
}

interface RowItemProps {
  product: DataProps;
  price: PriceProps;
  updated_at: DataProps;
}

interface DataProps {
  headLabel: string;
  data: string;
}

interface PriceProps extends DataProps {
  increasing?: boolean;
}

function RowItem({ product, price, updated_at }: RowItemProps) {
  return (
    <div className="flex flex-wrap justify-between items-center my-1 md:my-0 py-5 md:py-4 px-4 md:px-10 border md:border-t-0 border-solid border-gray-300 rounded md:rounded-none bg-white">
      {product && (
        <div className="flex flex-col md:flex-1 order-1 w-1/2 md:w-auto">
          <span className="md:hidden font-bold uppercase mb-4 truncate">
            {product.headLabel}
          </span>
          <span>{product.data}</span>
        </div>
      )}

      {price && (
        <div
          className={`flex flex-col order-3 md:order-2 w-1/2 md:w-auto  mt-6 md:mt-0 ${
            price.increasing !== undefined ? "md:pl-10" : ""
          }`}
        >
          <span className="md:hidden font-bold uppercase mb-4">
            {price.headLabel}
          </span>
          <span>
            {price.data}
            {price.increasing !== undefined &&
              (price.increasing ? (
                <i className="las la-caret-up text-sm text-green-700 ml-2 md:ml-8" />
              ) : (
                <i className="las la-caret-down text-sm text-red-700 ml-2 md:ml-8" />
              ))}
          </span>
        </div>
      )}

      {updated_at && (
        <div className="flex flex-col md:flex-1 order-2 md:order-3 w-1/2 md:w-auto md:text-right">
          <span className="md:hidden font-bold uppercase mb-4 truncate">
            {updated_at.headLabel}
          </span>
          <span>{updated_at.data}</span>
        </div>
      )}
    </div>
  );
}

export function formateDate(date: Date): string {
  return `${moment(date).format("MMMM").substr(0, 3)} ${moment(date).format(
    "DD, YYYY"
  )}`;
}

function OutlinedBtn({
  children,
  className = "",
  props,
}: {
  children: JSX.Element;
  className?: string;
  props?: any;
}) {
  return (
    <div
      {...props}
      className={`p-1 rounded border border-solid border-gray-300 text-sm bg-white hover:border-green-600 transition duration-100 ease-linear cursor-pointer ${className}`}
    >
      {children}
    </div>
  );
}
