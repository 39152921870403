import React, { CSSProperties } from "react";

interface ThemedButtonProps {
  children: JSX.Element;
  action: () => any;
  sideCss?: CSSProperties;
  sideClass?: string;
  css?: CSSProperties;
  className?: string;
}

function ThemedButton({
  children,
  action,
  css = {},
  className,
  sideCss = { left: 0 },
  sideClass = "",
}: ThemedButtonProps): JSX.Element {
  return (
    <button
      onClick={action}
      className={`flex items-center flex-shrink-0 justify-center h-full relative overflow-hidden
        text-white uppercase py-2 px-2 md:px-6 pl-10 md:pl-16
        bg-primary-800 hover:bg-primary-900 transition duration-100 ease-linear ${
          className ? className : ""
        }`}
      style={{ ...css }}
    >
      {children}
      <div
        className={`absolute top-0 w-12 h-full bg-white ${
          sideClass ? sideClass : ""
        }`}
        style={{
          transformOrigin: "left top",
          transform: "skewX(-25deg)",
          ...sideCss,
        }}
      />
    </button>
  );
}

export default ThemedButton;
