import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../provider";
import { ServiceStep } from "../../../Types";

interface ServiceItemProps extends ServiceStep {
  isActive?: boolean;
  isMobileCol?: boolean;
  goToTop?: boolean;
}

function ServiceItem({
  number,
  title,
  description,
  linkLabel,
  linkHref,
  sectionId,
  isActive = false,
  isMobileCol = false,
  goToTop = false,
}: ServiceItemProps): JSX.Element {
  const { isLg } = useContext(AppContext);
  const history = useHistory();

  return (
    <div className={`${isMobileCol && "flex-col md:flex-row"} flex h-full`}>
      <span
        className={`${
          isMobileCol ? "mb-3 md:mb-0 md:leading-10" : "leading-7 xl:leading-10"
        } flex-shrink-0 pr-5 font-candal text-gray-200 text-5xl md:text-7xl`}
      >
        {number}
      </span>

      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col">
          <a
            onClick={() => {
              history.push(linkHref);
              goToTop && !isLg && window.scrollTo(0, 1);
            }}
            href={sectionId}
            className={`${
              isActive && "text-primary-700"
            } mb-3 text-xl md:text-3xl uppercase font-bold hover:text-primary-700 transition duration-100 ease-linear`}
          >
            {title}
          </a>
          <span className={`text-xs md:text-base font-light mb-5 leading-5`}>
            {description}
          </span>
        </div>

        <a
          onClick={() => {
            history.push(linkHref);
            goToTop && !isLg && window.scrollTo(0, 1);
          }}
          href={sectionId}
          className="flex justify-between items-center hover:text-primary-700 transition duration-100 ease-linear"
        >
          <div className="text-xs md:text-base font-bold">{linkLabel}</div>

          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-grayMatte text-white">
            <i className="las la-angle-right" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default ServiceItem;
