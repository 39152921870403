import React, { useContext } from "react";
import { v4 as uuid } from "uuid";
import _string from "../../config/localization/strLng";
import { GeneralLayout } from "../../layout";
import { AboutConfig_Al } from "../../config/AboutConfig";
import { AppContext } from "../../provider";
import contact from "../contact";

function AboutUsScreen(): JSX.Element {
  const { isMd } = useContext(AppContext);
  return (
    <GeneralLayout pageName={_string.PAGES.about_us}>
      <div className={"outer-container"}>
        <div className="relative inner-container flex flex-wrap pt-16 md:pt-0 py-10 md:py-16 lg:pr-16">
          {AboutConfig_Al && (
            <>
              {AboutConfig_Al.items.map((item) => {
                return (
                  <div
                    key={uuid()}
                    className="flex flex-col justify-start my-4 md:my-10"
                    style={isMd ? { width: "100%" } : { width: item.width }}
                  >
                    {item.title && (
                      <h2
                        className="w-full font-bold text-xl md:text-3xl mb-8 md:mb-5 px-4"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></h2>
                    )}
                    {item.description &&
                      item.description.map((desc) => {
                        return (
                          <p
                            key={uuid()}
                            dangerouslySetInnerHTML={{ __html: desc }}
                            className="w-full text-base md:text-lg leading-5 md:leading-7 px-4"
                          ></p>
                        );
                      })}
                    {item.subSection && (
                      <div className="w-full flex flex-wrap text-base md:text-lg">
                        {item.subSection.map((subsection) => {
                          return (
                            <div
                              key={uuid()}
                              style={
                                isMd
                                  ? { width: "100%" }
                                  : { width: subsection.width }
                              }
                              className="px-4"
                            >
                              <h3 className="w-full font-bold mt-4 mb-5 md:mb-4">
                                {subsection.title}
                              </h3>
                              {subsection.description.map((subDesc) => {
                                return (
                                  <p className="w-full my-2" key={uuid()}>
                                    {subDesc}
                                  </p>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
          <contact.components.AsideContact height={850} />
        </div>
      </div>
    </GeneralLayout>
  );
}

export default AboutUsScreen;
