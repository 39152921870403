import React from "react";
import footer from "./";

function FooterLayout(): JSX.Element {
  return (
    <>
      <footer.components.MainFooter />
      <footer.components.Credits />
    </>
  );
}

export default FooterLayout;
