import MicroModal from "micromodal";
import React, { useContext, useState } from "react";
import { fetchApi } from "../../../config/core/Api";
import _string from "../../../config/localization/strLng";
import global from "../../../global";
import * as yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";
import { AppContext } from "../../../provider";

interface SellProductFormTYpes {
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  details: string;
  photo: File | "";
}

const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

let sellProductSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  lastName: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  email: yup
    .string()
    .email(_string.ERRORS.invalid_email)
    .required(_string.ERRORS.required_field),
  address: yup
    .string()
    .required(_string.ERRORS.required_field)
    .min(20, `20 ${_string.ERRORS.is_min_characters}`),
  details: yup
    .string()
    .required(_string.ERRORS.required_field)
    .min(20, `20 ${_string.ERRORS.is_min_characters}`),
  photo: yup
    .mixed()
    .required(_string.ERRORS.required_field)
    .test(
      "fileFormat",
      _string.ERRORS.unsupported_format,
      (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
    ),
});

function SellProductModalForm(): JSX.Element {
  const { notify } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [validateFormOnChange, setValidateFormOnChange] = useState<boolean>(
    false
  );
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  function handleSubmit(values: SellProductFormTYpes, { resetForm }: any) {
    setIsValidForm(true);

    var dataFormData = new FormData();

    dataFormData.append("first_name", values.firstName);
    dataFormData.append("last_name", values.lastName);
    dataFormData.append("photo", values.photo);
    dataFormData.append("address", values.address);
    dataFormData.append("email", values.email);
    dataFormData.append("details", values.details);

    setIsLoading(true);
    fetchApi({
      url: "/valuate/product",
      method: "POST",
      data: dataFormData,
    })
      .then((r) => {
        notify(_string.MESSAGES.request_submit_success, "success");

        setTimeout(() => {
          setIsValidForm(false);
          MicroModal.close("sell-product-modal");
        }, 3000);
      })
      .catch((e) => {
        notify(_string.ERRORS.register_failed, "error");
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
        resetForm();
      });
  }

  return (
    <div
      className="modal micromodal-slide my-10"
      id="sell-product-modal"
      aria-hidden="true"
    >
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close>
        <div className="modal__container" role="dialog" aria-modal="true">
          <header className="flex flex-col p-5">
            <button
              onClick={() => {
                MicroModal.close("sell-product-modal");
              }}
              className="self-end"
              aria-label="Close modal"
              data-micromodal-close
            >
              <i className="text-white text-2xl las la-times"></i>
            </button>
          </header>
          <main className="modal__content flex pt-4 md:pt-6 pb-12 px-4 md:px-20">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                address: "",
                email: "",
                photo: "",
                details: "",
              }}
              validationSchema={sellProductSchema}
              onSubmit={handleSubmit}
              validateOnChange={validateFormOnChange}
              validateOnBlur={validateFormOnChange}
            >
              {({ errors, touched, values }) => (
                <Form className="flex flex-col w-full md:flex-row md:flex-wrap">
                  <div className="flex w-full md:w-1/2 md:pr-5 md:mb-10">
                    <Field name="firstName">
                      {(fieldProps: FieldProps) => (
                        <FormInput
                          type="text"
                          placeholder={_string.LABELS.first_name.toUpperCase()}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="flex w-full md:w-1/2 md:pl-5 md:mb-10">
                    <Field name="lastName">
                      {(fieldProps: FieldProps) => (
                        <FormInput
                          type="text"
                          placeholder={_string.LABELS.last_name.toUpperCase()}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="flex w-full md:w-1/2 md:pr-5 md:mb-10">
                    <Field name="address">
                      {(fieldProps: FieldProps) => (
                        <FormInput
                          type="text"
                          placeholder={_string.LABELS.address.toUpperCase()}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="flex w-full md:w-1/2 md:pl-5 md:mb-10">
                    <Field name="email">
                      {(fieldProps: FieldProps) => (
                        <FormInput
                          type="text"
                          placeholder={_string.LABELS.email.toUpperCase()}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>

                  <div
                    className="flex w-full md:mb-16"
                    style={{ minHeight: 200 }}
                  >
                    <Field name="details">
                      {(fieldProps: FieldProps) => (
                        <FormInput
                          type="textarea"
                          placeholder={_string.LABELS.details.toUpperCase()}
                          {...fieldProps}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="flex flex-col md:flex-row justify-between items-center w-full">
                    <div className="flex justify-center w-full md:w-auto mb-5 md:mb-0">
                      <Field name="photo">
                        {(fieldProps: FieldProps) => (
                          <>
                            <input
                              type="file"
                              id="image-file"
                              accept="image/jpg,image/jpeg,image/gif,image/png"
                              onChange={(e) => {
                                if (e.target.files?.length) {
                                  fieldProps.form.setFieldValue(
                                    "photo",
                                    e.target.files[0]
                                  );
                                }
                              }}
                              hidden
                            />
                            <label
                              htmlFor="image-file"
                              className={`relative flex items-center justify-center w-full md:w-auto px-6 py-4 rounded-sm bg-white text-grayMatte hover:bg-green-800 hover:text-white cursor-pointer`}
                            >
                              {isValidForm ? (
                                <i className="bg-white la-check-circle las p-1 rounded-full text-2xl text-green-800"></i>
                              ) : (
                                <i className="text-2xl las la-image mr-3"></i>
                              )}
                              <span>{_string.ACTIONS.upload_photo}</span>
                              {fieldProps.meta.error && (
                                <span
                                  style={{ bottom: -30 }}
                                  className="absolute left-0 px-2 py-1 text-center text-red-600 w-full truncate"
                                >
                                  {fieldProps.meta.error}
                                </span>
                              )}
                            </label>
                          </>
                        )}
                      </Field>
                    </div>

                    <global.Button
                      loading={isLoading}
                      verticalPadding={3}
                      horizontalPadding={16}
                      htmlType="submit"
                      bgColor="w-full md:w-auto bg-green-800 text-white"
                      hoverBgColor="hover:bg-white hover:text-grayMatte"
                      onClick={() => {
                        setValidateFormOnChange(true);
                      }}
                    >
                      {_string.ACTIONS.send.toUpperCase()}
                    </global.Button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
        </div>
      </div>
    </div>
  );
}

export default SellProductModalForm;

interface FormInputProps extends FieldProps {
  placeholder: string;
  type: "text" | "number" | "email" | "textarea";
}

export function FormInput({ placeholder, type, ...props }: FormInputProps) {
  return (
    <global.InputV2
      type={type}
      placeholder={placeholder}
      error={props.meta.error}
      {...props.field}
    />
  );
}
