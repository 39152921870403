import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { homeInfoConfig_Al } from "../../../config/HomeInfoConfig";
import global from "../../../global";

function ProductList(): JSX.Element {
  let history = useHistory();

  const [hashList, setHashList] = useState<string[]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (homeInfoConfig_Al && homeInfoConfig_Al.servicesSteps.length) {
      let tempHash: string[] = [];
      homeInfoConfig_Al.servicesSteps.forEach((service) => {
        tempHash.push(service.sectionId);
      });

      setHashList(tempHash);
    }
  }, []);

  useEffect(() => {
    hashList.forEach((hash, index) => {
      if (history.location.hash === hash) {
        setSelectedItemIndex(index);
      }
    });
  }, [hashList, history.location.hash]);

  return homeInfoConfig_Al &&
    selectedItemIndex != null &&
    homeInfoConfig_Al.servicesSteps[selectedItemIndex].subItem?.length ? (
    <div className="services-list border-solid border-t border-l border-r border-gray-300">
      {homeInfoConfig_Al.servicesSteps[selectedItemIndex].subItem?.map(
        (subItem, index) => (
          <ListItem key={index} {...subItem} />
        )
      )}
    </div>
  ) : (
    <></>
  );
}

export default ProductList;

interface ListItemProps {
  label: string;
  photoUrl: string;
}

function ListItem({ label, photoUrl }: ListItemProps) {
  return (
    <div className="services-list_item h-16 border-solid border-b border-gray-300 cursor-pointer">
      <span className="services-list_item-label ml-6 md:ml-24 lg:ml-48 text-lg truncate">
        {label}
      </span>

      <div className="services-list_item-bg">
        <div className="bg-primary-700" />
        <global.Image src={photoUrl} />
      </div>
    </div>
  );
}
