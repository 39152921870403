import _string from "../../config/localization/strLng";

interface MenuPages {
  label: string;
  page: string;
}

export let pages: MenuPages[] = [
  {
    label: _string.PAGES.home,
    page: "/",
  },
  {
    label: _string.PAGES.services,
    page: "/services#collection",
  },
  {
    label: _string.PAGES.about_us,
    page: "/about-us",
  },
  {
    label: _string.PAGES.contact,
    page: "/contact-us",
  },
];
