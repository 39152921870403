export const Alumin = require("./Alumin.png");
export const Automjete = require("./Automjete.png");
export const Baker = require("./Baker.png");
export const Bateri = require("./Bateri.png");
export const Bronx = require("./Bronx.png");
export const Dural = require("./Dural.png");
export const Gize = require("./Gize.png");
export const Inoks = require("./Inoks.png");
export const Kabell = require("./Kabell.png");
export const Kanace = require("./Kanace.png");
export const Plumb = require("./Plumb.png");
export const Radiatore = require("./Radiatore.png");
export const Sponde = require("./Sponde.png");
export const Skrap = require("./Skrap.png");
export const Tela = require("./Tela.png");
export const Zink = require("./Zink.png");
export const GrumbullimKartonPalstikQelq = require("./GrumbullimKartonPalstikQelq.jpg");
export const TregetimHekurNdertimi = require("./TregetimHekurNdertimi.jpg");
