import Button from "./Button";
import Image from "./Image";
import Input from "./Input";
import InputV2 from "./InputV2";
import PaginationArrow from "./PagnationArrow";
import PaginationLoadMore from "./PaginationLoadMore";

export default {
  Button,
  Image,
  Input,
  InputV2,
  PaginationArrow,
  PaginationLoadMore,
};
