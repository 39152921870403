import React, { createContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config_Al } from "../config/CekreziConfig";
import { CekreziConfig } from "../Types";

type NotificationType = "success" | "error" | "warn" | "info";

interface AppProviderProps {
  children: JSX.Element;
}
interface AppContextTypes {
  cekreziConfig: CekreziConfig;
  isMd: boolean;
  isLg: boolean;
  isSm: boolean;
  isXl: boolean;
  notify: (message: string, type?: NotificationType, title?: string) => void;
}

const APP_CONTEXT_INITIAL_VALUES = {
  cekreziConfig: config_Al,
  isMd: false,
  isLg: false,
  isSm: false,
  isXl: false,
  notify: (message: string, type?: NotificationType, title?: string) =>
    undefined,
};

export const AppContext = createContext<AppContextTypes>({
  ...APP_CONTEXT_INITIAL_VALUES,
});

function AppProvider({ children }: AppProviderProps) {
  const [cekreziConfig] = useState<CekreziConfig>(config_Al);
  const [isMd, setIsMd] = useState(false);
  const [isLg, setIsLg] = useState(false);
  const [isSm, setIsSm] = useState(false);
  const [isXl, setIsXl] = useState(false);

  useEffect(() => {
    // console.log(_string.getLanguage());
    // console.log(_string.getInterfaceLanguage());

    handleAppResize();

    window.addEventListener("resize", handleAppResize);
    return () => {
      window.removeEventListener("resize", handleAppResize);
    };
  }, []);

  function handleAppResize() {
    let width = window.innerWidth;

    if (width < 768) {
      setIsMd(true);
    } else {
      setIsMd(false);
    }

    if (width < 1024) {
      setIsLg(true);
    } else {
      setIsLg(false);
    }

    if (width < 1280) {
      setIsXl(true);
    } else {
      setIsXl(false);
    }

    if (width < 640) {
      setIsSm(true);
    } else {
      setIsSm(false);
    }
  }

  function notify(message: string, type?: NotificationType, title?: string) {
    if (type) {
      toast[type](message);
    } else {
      toast(message);
    }
  }

  const providerValue = {
    cekreziConfig,
    isMd,
    isLg,
    isSm,
    isXl,
    notify,
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <AppContext.Provider value={providerValue}>
        {children}
      </AppContext.Provider>
    </>
  );
}

export default AppProvider;
