import React, { useContext } from "react";
import footer from "../";
import _string from "../../../config/localization/strLng";
import { AppContext } from "../../../provider";
import logo from "../../../assets/img/logo_main.png";
import Global from "../../../global";
import global from "../../../global";
const sponsor_1_logo = require("../../../assets/img/sponsor_1_logo.png");
const sponsor_2_logo = require("../../../assets/img/sponsor_2_logo.png");
const sponsor_3_logo = require("../../../assets/img/sponsor_3_logo.png");

function MainFooter(): JSX.Element {
  const { cekreziConfig } = useContext(AppContext);

  return (
    <div className="outer-container bg-white">
      <div className="flex flex-col lg:flex-row inner-container">
        {/* Footer Info - Left side */}
        <div className="flex flex-col items-start lg:w-4/12 p-4 lg:pr-16 lg:pb-10">
          <Global.Image className="h-20 mb-8" src={logo} alt="" />
          <span className="font-light leading-5" style={{ maxWidth: 800 }}>
            {_string.STRINGS.footer_description}
          </span>

          <div className="w-full flex flex-wrap">
            <div className="flex w-1/3 justify-center items-center p-4">
              <global.Image src={sponsor_1_logo} />
            </div>
            <div className="flex w-1/3 justify-center items-center p-4">
              <global.Image src={sponsor_2_logo} />
            </div>
            <div className="flex w-1/3 justify-center items-center p-4">
              <global.Image src={sponsor_3_logo} />
            </div>
          </div>
        </div>
        {/* End Footer Info - Left side */}

        {/* Footer Address - Right side */}
        <div className="flex flex-col md:flex-row md:justify-around lg:w-8/12 bg-grayMatte px-2 pb-2 pt-8 md:pb-20 md:pt-24">
          {cekreziConfig &&
            cekreziConfig.address.map((address, index) => (
              <footer.components.FooterAddress key={index} address={address} />
            ))}
        </div>
        {/* End Footer Address - Right side */}
      </div>
    </div>
  );
}

export default MainFooter;
