import { AxiosError, AxiosResponse } from "axios";
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { fetchApi } from "../config/core/Api";
import Storage from "../config/core/Storage";
import _string from "../config/localization/strLng";
import { UserInfo } from "../Types";
import { AppContext } from "./AppProvider";

interface UserProviderProps {
  //  extends RouteComponentProps<any, any>
  children: JSX.Element;
}
interface IUserContextTypes {
  jwt_token: string | null;
  setJwtToken: Dispatch<string>;
  userInfo: UserInfo | null | false;
  setUserInfo: Dispatch<UserInfo>;
  registrationCode: string;
  setRegistrationCode: Dispatch<string>;
  handleAuthFailure: (result: AxiosResponse<any>) => void;
  handleLogout: () => void;
}

const USER_CONTEXT_INITIAL_VALUES = {
  jwt_token: null,
  setJwtToken: (jwt_token: string) => undefined,
  userInfo: null,
  setUserInfo: (userInfo: UserInfo) => undefined,
  registrationCode: "",
  setRegistrationCode: (registrationCode: string) => undefined,
  handleAuthFailure: (result: AxiosResponse<any>) => undefined,
  handleLogout: () => undefined,
};

export const UserContext = createContext<IUserContextTypes>({
  ...USER_CONTEXT_INITIAL_VALUES,
});

const UserProvider = ({ children }: UserProviderProps) => {
  const { notify } = useContext(AppContext);

  const [jwt_token, setJwtToken] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null | false>(null);
  const [registrationCode, setRegistrationCode] = useState<string>("");

  const handleLogout = useCallback(() => {
    Storage.removeItem("auth_token");
    setUserInfo(false);
  }, []);

  const handleAuthFailure = useCallback(
    (result: AxiosResponse<any>) => {
      if (result.status === 401) {
        handleLogout();
      }
    },
    [handleLogout]
  );

  const getUser = useCallback(
    (token: string) => {
      fetchApi({
        method: "GET",
        url: "/user",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((r: AxiosResponse<UserInfo>) => {
          if (r.data) {
            setUserInfo(r.data);
            handleAuthFailure(r);
          } else {
            setUserInfo(false);
          }
        })
        .catch((e: AxiosError) => {
          notify(_string.ERRORS.something_went_wrong, "error");
          handleLogout();
          setUserInfo(false);
        })
        .finally(() => {});
    },
    [handleAuthFailure, notify, handleLogout]
  );

  useEffect(() => {
    console.log(Storage.getItem("auth_token"));
    let token = Storage.getItem("auth_token");
    if (token !== null) {
      setJwtToken(token);
    } else {
      setUserInfo(false);
    }
  }, []);

  useEffect(() => {
    if (jwt_token) {
      getUser(jwt_token);
    }
  }, [jwt_token, getUser]);

  const providerValue = {
    jwt_token,
    setJwtToken,
    userInfo,
    setUserInfo,
    registrationCode,
    setRegistrationCode,
    handleAuthFailure,
    handleLogout,
  };

  return (
    <UserContext.Provider value={providerValue}>
      {/* {children} */}
      {userInfo !== null ? (
        children
      ) : (
        <div className="absolute w-full h-full flex items-center justify-center bg-grayMatte">
          <i className="text-5xl text-green-700 las la-circle-notch animate-spin"></i>
        </div>
      )}
    </UserContext.Provider>
  );
};

export default UserProvider;
