import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../provider";

function CheckRole(): JSX.Element {
  let history = useHistory();

  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    if (userInfo) {
    } else {
      if (window.location.pathname === "/profile") {
        history.replace("/");
      }
    }
  }, [history, userInfo]);

  return <></>;
}

export default CheckRole;
