import React, { useContext, useState } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import * as yup from "yup";
import MicroModal from "micromodal";
import { fetchApi } from "../../../config/core/Api";
import _string from "../../../config/localization/strLng";
import global from "../../../global";
import { AppContext, UserContext } from "../../../provider";
import { FormInput } from "./LoginModalForm";

interface EditProfileFormTypes {
  name: string;
  phone: string;
  email: string;
}

const editProfileFormSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  phone: yup
    .number()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(9999999999999, `14 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  email: yup
    .string()
    .email(_string.ERRORS.invalid_email)
    .required(_string.ERRORS.required_field),
});

function EditProfileModal(): JSX.Element {
  const { notify } = useContext(AppContext);
  const { userInfo } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [validateFormOnChange, setValidateFormOnChange] = useState<boolean>(
    false
  );

  function handleSubmit(values: EditProfileFormTypes, { resetForm }: any) {
    if (!userInfo) return;

    var formData = new FormData();

    formData.append("name", values.name);
    formData.append("phone", values.phone);
    formData.append("email", values.email);

    setIsLoading(true);
    fetchApi({
      url: `/${userInfo.id}/edit`,
      method: "PUT",
      data: formData,
    })
      .then((r) => {
        notify(_string.MESSAGES.profile_edit_success, "success");

        setTimeout(() => {
          MicroModal.close("edit-profile-modal");
        }, 3000);
      })
      .catch((e) => {
        notify(_string.ERRORS.something_went_wrong, "error");
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
        resetForm();
      });
  }

  return (
    <div
      className="modal micromodal-slide"
      id="edit-profile-modal"
      aria-hidden="true"
    >
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close>
        <div
          className="modal__container text-base"
          role="dialog"
          aria-modal="true"
        >
          <header className="flex flex-col p-5">
            <button
              onClick={() => {
                MicroModal.close("edit-profile-modal");
              }}
              className="self-end"
              aria-label="Close modal"
              data-micromodal-close
            >
              <i className="text-white text-2xl las la-times"></i>
            </button>
          </header>
          <main className="modal__content flex flex-col items-center justify-center pt-4 md:pt-6 pb-12 px-4 md:px-20">
            <p className="text-green-700 font-bold w-full text-center text-lg md:text-2xl mb-6 md:mb-10">
              {_string.LABELS.edit_profile}
            </p>

            <Formik
              initialValues={{
                name: "",
                phone: "",
                email: "",
              }}
              validationSchema={editProfileFormSchema}
              onSubmit={handleSubmit}
              validateOnChange={validateFormOnChange}
              validateOnBlur={validateFormOnChange}
            >
              {({ errors, touched, values }) => {
                return (
                  <Form className="flex flex-wrap md:flex-wrap">
                    <div className="flex w-full md:w-1/2 md:pr-3">
                      <Field name="name">
                        {(fieldProps: FieldProps) => (
                          <FormInput
                            type="text"
                            placeholder={_string.LABELS.first_name}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="flex w-full md:w-1/2 md:pl-3">
                      <Field name="email">
                        {(fieldProps: FieldProps) => (
                          <FormInput
                            type="text"
                            placeholder={_string.LABELS.last_name}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="flex w-full md:w-1/2 md:pr-3">
                      <Field name="phone">
                        {(fieldProps: FieldProps) => (
                          <FormInput
                            type="number"
                            placeholder={_string.LABELS.phone_number}
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="w-full flex justify-end mt-6 md:mt-10">
                      <global.Button
                        loading={isLoading}
                        verticalPadding={3}
                        horizontalPadding={16}
                        htmlType="submit"
                        bgColor="w-full md:w-auto bg-green-800 text-white"
                        hoverBgColor="hover:bg-white hover:text-grayMatte"
                        onClick={() => {
                          setValidateFormOnChange(true);
                        }}
                      >
                        {_string.ACTIONS.send.toUpperCase()}
                      </global.Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </main>
        </div>
      </div>
    </div>
  );
}

export default EditProfileModal;
