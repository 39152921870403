import { AboutConfig } from "../Types";

export const AboutConfig_Al: AboutConfig = {
  items: [
    {
      width: "100%",
      title: "RRETH NESH",
      description: [
        "<strong>ÇEKREZI GROUP</strong> u krijua në vitin 2009 si njesi e veçantë dhe autonome brënda një kompanie grumbullimi e tregtimi hekuri skrap dhe metale me ngjyrë. Me aktivitet në Vaqarr, Tiranë. Gjatë kësaj kohe, jemi rritur sëbashku me klientët tanë duke u përpjekur të japim përhere zgjidhjet më të mira në fushën e grumbullimit dhe përpunimit. Në vitin 2014 kjo rritje e vazhdueshme solli edhe zgjerimin tonë me një pikë grumbullimi tjetër për të qënë sa më afër klientëve tanë.",
      ],
    },
    {
      width: "50%",
      title: "FUSHA VEPRIMIT",
      description: [
        "Grumbullim dhe tregtim skrapi hekuri me qëllim riciklimi.Import- Eksport. Grumbullim, seleksionim, përpunim metale me ngjyrë me qëllim riciklimi. Transport për kompaninë e për të tretë.",
      ],
    },
    {
      width: "50%",
      title: "STRATEGJIA DHE VIZIONI",
      description: [
        "Përpiqemi të ofrojmë shërbime të shkëlqyera dhe ndërtimin e marrëdhënieve afatgjata me të gjithë klientët tanë, duke siguruar që ata gjithmonë te marrin çmimin më të mirë në treg.Ne besojmë fuqimisht në komunikimin dhe shërbimet e ofruara, për një marrëdhënie afatgjatë me klientët tanë.",
      ],
    },
    {
      width: "100%",
      title: "VLERAT",
      description: [
        "<strong>ÇEKREZI GROUP</strong>, si të gjitha kompanitë e ligjshme të riciklimit, përkrah mënyrën e saktë të vendosjes së të gjitha materialeve të mbledhura nga vetja për të siguruar një të ardhme të gjelbër për të gjithë.Ne jemi shumë të vetëdijshëm për çështjet e mjedisit ku i përmbahemi në mënyrë rigoroze të githë ligjeve të riciklimit të fuqi. Kjo dëshmohet nga licensat e marra nga Qëndra Kombëtare e Liçensimit (QKL):",
      ],
      subSection: [
        {
          width: "50%",
          title:
            "Kjo dëshmohet nga licensat e marra nga Qëndra Kombëtare e Liçensimit (QKL):",
          description: ["III.1.A", "III.1.B ", "III.2.B"],
        },
        {
          width: "50%",
          title:
            "Si dhe certifikata te ndryshme nga Autoriteti Certifikues AQSCERT:",
          description: [
            "-EN ISO 14001:2004",
            "-BS OHSAS 18001:2007",
            "-EN ISO 9001:2008",
            "-ISO 39001:2012",
          ],
        },
      ],
    },
    {
      width: "50%",
      title: "OBJEKTIVAT E BIZNESIT",
      description: [
        "Qëllimet tona janë për të mbështetur shërbim cilësor për klientët tanë. Ofrimi i çmimeve më të larta për klientët tanë. Mundesimi i marrëdhënieve të hapura biznesi me klientët tanë.",
      ],
    },
    {
      width: "50%",
      title: "POLITIKAT E CILËSISE",
      description: [
        "<strong>ÇEKREZI GROUP</strong> është tregtar i liçencuar për grumbullim dhe riciklim të skrapit dhe metaleve të tjera, në bazë të ligjeve të shtetit Shqipëtar. </br> <strong>ÇEKREZI GROUP</strong> nuk është kurrsesi i perfshirë në grumbullimin e metaleve të paligjshme. Disa prej këtyre produkteve perfshijnë: kapak pusetash, materiale të siguruara në mënyre jo të ligjshme si dhe lëndët plasese si bomba, predha, plumba etj.",
      ],
    },
    {
      width: "100%",
      title: "KONKURRENCA",
      description: [
        "<strong>ÇEKREZI GROUP</strong> është një kompani e konsoliduar. Por kjo nuk do të thotë që ne nuk jemi në kërkim të inovacionit dhe përmirësimit të vazhdueshëm duke ju pershtatur tregut dhe kërkesave të klientëve tanë në mëyrë më efikase.",
      ],
      subSection: [
        {
          width: "100%",
          title:
            "Disa nga cilesitë që e dallojne kompaninë tonë nga konkurentët janë:",
          description: [
            "Staf drejtues dhe një ekip dinamik prej 18 punonjësish.",
            "Pozicionimi në treg si një kompani me reputacion tek klientët.",
            "Ofrimi i çmimeve konkurente për klientët.Shërbim dhe vëmendje maksimale ndaj klientevë.",
          ],
        },
      ],
    },
    {
      width: "100%",
      title: "STRATEGJIA E RRITJES",
      description: [
        "Ne kemi një ekip të dedikuar në punë, i cili na mundëson që të ofrojmë shërbim të mirë dhe të sigurojmë rrjedhshmëri të biznesit. Ekipi ynë merr trajnimin e duhur për të kënaqur nevojat e klientit tonë. Puna në grup drejt rritjes është thelbësore për biznesin tonë.",
      ],
    },
  ],
};
