import ThemedButton from "./ThemedButton";
import LoginModalForm from "./LoginModalForm";
import RegisterModalForm from "./RegisterModalForm";
import UniqueRegistrationCodeModal from "./UniqueRegistrationCodeModal";
import EditProfileModal from "./EditProfileModal";

export default {
  ThemedButton,
  LoginModalForm,
  RegisterModalForm,
  UniqueRegistrationCodeModal,
  EditProfileModal,
};
