import React, { Dispatch } from "react";
import { PaginationPaths } from "../Types";

interface PaginationArrowsProps {
  pagination: PaginationPaths;
  setPagination: Dispatch<PaginationPaths>;
}

function PaginationArrows({
  pagination,
  setPagination,
}: PaginationArrowsProps): JSX.Element {
  function handleNext() {
    if (pagination.nextPageUrl) {
      setPagination({
        ...pagination,
        currentPath: pagination.nextPageUrl,
      });
    }
  }

  function handlePrev() {
    if (pagination.prevPageUrl) {
      setPagination({
        ...pagination,
        currentPath: pagination.prevPageUrl,
      });
    }
  }

  return (
    <div className={`w-full flex justify-end mt-3 `}>
      <button
        onClick={handlePrev}
        disabled={!pagination.prevPageUrl}
        className={`w-8 h-8 flex mr-3 items-center justify-center border border-solid border-gray-400 rounded ${
          !pagination.prevPageUrl ? "text-gray-400" : ""
        }`}
        type="button"
      >
        <i className="las la-angle-left"></i>
      </button>
      <button
        onClick={handleNext}
        disabled={!pagination.nextPageUrl}
        className={`w-8 h-8 flex items-center justify-center border border-solid border-gray-400 rounded ${
          !pagination.nextPageUrl ? "text-gray-400" : ""
        }`}
        type="button"
      >
        <i className="las la-angle-right"></i>
      </button>
    </div>
  );
}

export default PaginationArrows;
