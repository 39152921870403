import React, { useEffect } from "react";
import _string from "../../config/localization/strLng";
import navigation from "./";
import MicroModal from "micromodal";

function GuestActions(): JSX.Element {
  useEffect(() => {
    MicroModal.init();
  }, []);

  return (
    <>
      <navigation.AuthModal />
      <navigation.components.UniqueRegistrationCodeModal />
      <navigation.components.ThemedButton
        action={() => {
          MicroModal.show("auth-modal");
        }}
      >
        {_string.ACTIONS.login}
      </navigation.components.ThemedButton>
    </>
  );
}

export default GuestActions;
