import React from "react";
import _string from "../../../config/localization/strLng";
import { homeInfoConfig_Al } from "../../../config/HomeInfoConfig";
import Global from "../../../global";
import cr_1 from "../../../assets/img/cr_1.png";
import cr_2 from "../../../assets/img/cr_2.png";
import cr_3 from "../../../assets/img/cr_3.png";
import cr_4 from "../../../assets/img/cr_4.png";
import triangle_shape from "../../../assets/img/triangle_shape.svg";

function CollectionProcess(): JSX.Element {
  return (
    <>
      <div className="outer-container">
        <div className="inner-container flex justify-center flex-wrap py-4 lg:py-12 px-4">
          {homeInfoConfig_Al && homeInfoConfig_Al.collectionProcess && (
            <>
              <div
                style={{ maxWidth: 800 }}
                className="w-full lg:w-1/2 lg:pr-6"
              >
                <p
                  style={{ maxWidth: 450 }}
                  className="mb-6 lg:mb-0 text-2xl xl:text-3xl font-bold uppercase leading-8"
                >
                  {homeInfoConfig_Al.collectionProcess.title}
                </p>
              </div>
              <div style={{ maxWidth: 800 }} className="flex w-full lg:w-1/2">
                <Global.Image className="w-12 h-12 mr-4" src={triangle_shape} />
                <p className="text-base font-light leading-6">
                  {homeInfoConfig_Al.collectionProcess.description}
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="outer-container">
        <div className="inner-container flex flex-wrap justify-around py-6 md:py-12 md:mb-12 px-4">
          <Item
            img={cr_1}
            title={_string.LABELS.individual_collector}
            cornerPosition="bottom"
          />
          <Item img={cr_2} title={_string.LABELS.collection_point} />
          <Item
            img={cr_3}
            title={_string.LABELS.collection_center}
            cornerPosition="bottom"
          />
          <Item img={cr_4} title={_string.LABELS.company_recycle_export} />
        </div>
      </div>
    </>
  );
}

export default CollectionProcess;

interface ItemProps {
  title: string;
  img: string;
  cornerPosition?: "top" | "bottom";
}

function Item({ title, img, cornerPosition = "top" }: ItemProps) {
  return (
    <div className="w-full sm:w-1/2 lg:w-3/12 flex items-center justify-center p-4 ">
      <div className="relative flex flex-col items-center justify-center p-4 w-full h-full lg:w-64 lg:h-64 hover:bg-green-200 transition duration-100 ease-linear cursor-pointer">
        <Global.Image
          src={img}
          className="object-contain my-4 lg:my-8 w-24 h-24 sm:w-32 sm:h-32 md:h-24 md:w-24 lg:w-32 lg:h-32"
        />

        <span className="text-xs md:text-base font-bold uppercase text-center">
          {title}
        </span>

        <Global.Image
          className="w-8 h-8 absolute"
          src={triangle_shape}
          style={
            cornerPosition === "top"
              ? {
                  top: 0,
                  left: 0,
                }
              : {
                  bottom: 0,
                  right: 0,
                  transform: `rotate(180deg)`,
                }
          }
        />
      </div>
    </div>
  );
}
