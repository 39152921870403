import React from "react";
import _string from "../../config/localization/strLng";
import { GeneralLayout } from "../../layout";
import contact from "./";

function ContactScreen(): JSX.Element {
  return (
    <GeneralLayout pageName={_string.PAGES.contact}>
      <div className="outer-container">
        <div className="inner-container">
          <div className={"flex flex-col lg:flex-row lg:pb-20"}>
            <div className="flex flex-col lg:flex-row w-full lg:w-7/12 px-4 py-16 lg:py-32 lg:pr-16 xl:pr-32 xxl:pr-48">
              <contact.components.ContactForm />
            </div>
            <div className="relative hidden lg:flex w-full lg:w-5/12 lg:pb-20">
              <contact.components.Locations />
            </div>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
}

export default ContactScreen;
