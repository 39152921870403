import { AxiosError, AxiosResponse } from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { fetchApi, FetchApiProps } from "../../../config/core/Api";
import _string from "../../../config/localization/strLng";
import global from "../../../global";
import { AppContext, UserContext } from "../../../provider";
import { PaginationPaths, ProfileNotificationsResponse } from "../../../Types";

export interface NotificationItemTypes {
  title?: string;
  desc: string;
}

function SideNotifications(): JSX.Element {
  const { notify } = useContext(AppContext);
  const { handleAuthFailure, jwt_token } = useContext(UserContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationItemTypes[]>(
    []
  );
  const [notificationPagination, setNotificationPagination] = useState<
    PaginationPaths
  >({
    nextPageUrl: "",
    prevPageUrl: "",
    currentPath: "/notifications",
  });

  const getNotifications = useCallback(
    (
      statePagination: PaginationPaths,
      path: string,
      stateNotifications: NotificationItemTypes[]
    ) => {
      setLoading(true);
      fetchApi(
        Object.assign(
          {
            method: "GET",
            url: path,
            headers: { Authorization: `Bearer ${jwt_token}` },
          },
          path.includes("http")
            ? {
                fullUrl: true,
              }
            : {}
        ) as FetchApiProps
      )
        .then((r: AxiosResponse<ProfileNotificationsResponse>) => {
          let tempNotifications: NotificationItemTypes[] = [];
          if (r.status === 200) {
            const { data, next_page_url, prev_page_url } = r.data;

            data.forEach((notification) => {
              tempNotifications.push({
                desc: notification.content,
              });
            });

            let nextUrl: string = "";
            let prevUrl: string = "";

            if (next_page_url) {
              nextUrl = next_page_url;
            } else {
              nextUrl = "";
            }

            if (prev_page_url) {
              prevUrl = prev_page_url;
            } else {
              prevUrl = "";
            }

            setNotificationPagination({
              ...statePagination,
              currentPath: path,
              nextPageUrl: nextUrl,
              prevPageUrl: prevUrl,
            });
          }

          setNotifications([...stateNotifications, ...tempNotifications]);
          handleAuthFailure(r);
        })
        .catch((e: AxiosError) => {
          console.log(e.message);
          notify(_string.ERRORS.something_went_wrong, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [jwt_token, handleAuthFailure, notify, setNotificationPagination]
  );

  useEffect(() => {
    if (jwt_token && notificationPagination.currentPath) {
      getNotifications(
        notificationPagination,
        notificationPagination.currentPath,
        notifications
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPagination.currentPath, jwt_token, getNotifications]);

  return (
    <div className="flex flex-grow flex-col">
      <div className="flex justify-between items-center py-4 px-10 rounded-t-sm lg:rounded-none bg-green-700">
        <h4 className="font-bold uppercase flex-1 text-center text-white">
          {_string.LABELS.notifications}
        </h4>
      </div>
      {notifications.length === 0 ? (
        <span className="flex p-4 border border-t-0 border-solid border-gray-300 text-gray-700 text-center">
          {_string.MESSAGES.no_notifications}
        </span>
      ) : (
        <>
          <div
            style={{
              maxHeight: 50 * 15,
              overflowY: "auto",
            }}
          >
            {notifications.map((item) => (
              <NotificationItem key={uuid()} notification={item} />
            ))}
          </div>

          {isLoading && (
            <div className="flex justify-center items-center p-2 border-gray-300 border-solid border-l border-r border-b">
              <div className="">
                <i className="text-3xl las la-circle-notch animate-spin"></i>
              </div>
            </div>
          )}

          <global.PaginationLoadMore
            pagination={notificationPagination}
            setPagination={setNotificationPagination}
          />
        </>
      )}
    </div>
  );
}

export default SideNotifications;

function NotificationItem({
  notification,
}: {
  notification: NotificationItemTypes;
}) {
  return (
    <div className="flex p-4 border border-t-0 border-solid border-gray-300">
      <div className="flex flex-shrink-0 w-5 mr-2">
        <i className="las la-bell" />
      </div>
      <div className="flex flex-col">
        <span>{notification.desc}</span>
      </div>
    </div>
  );
}
