import React from "react";
import global from "../../../global";
import comp from "./";
import _string from "../../../config/localization/strLng";
import triangle_shape from "../../../assets/img/triangle_shape.svg";
import MicroModal from "micromodal";

function ActionCards(): JSX.Element {
  return (
    <div className="outer-container">
      <div className="inner-container flex justify-center flex-wrap mt-4 mb-10 lg:mt-4 lg:mb-8">
        <Card
          title={_string.ACTIONS.unregister_car}
          action={() => {
            MicroModal.show("unregister-car-modal");
          }}
        />
        <comp.UnregisterCardModalForm />
        <Card
          title={_string.ACTIONS.sell_product}
          action={() => {
            MicroModal.show("sell-product-modal");
          }}
        />
        <comp.SellProductModalForm />
      </div>
    </div>
  );
}

export default ActionCards;

interface CardProps {
  title: string;
  action: any;
}

function Card({ title, action }: CardProps) {
  return (
    <div
      onClick={action}
      style={{ maxWidth: 800 }}
      className="flex w-full my-2 lg:my-0 lg:w-1/2 h-32 md:h-48 lg:h-64 px-4"
    >
      <div className="flex flex-1 justify-start border border-solid border-gray-300 hover:shadow-lg hover:text-green-700 cursor-pointer transition duration-100 ease-linear">
        <global.Image
          className="w-16 lg:w-32 self-start"
          src={triangle_shape}
          alt=""
        />

        <div className="flex flex-1 justify-between items-center h-full">
          <h3 className="text-2xl lg:text-3xl font-bold uppercase whitespace-pre-line">
            {title}
          </h3>

          <div className="flex items-center justify-center w-6 h-6 lg:w-8 lg:h-8 rounded-full bg-grayMatte text-white ml-4 mr-4 lg:mr-16">
            <i className="las la-angle-right lg:text-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
}
