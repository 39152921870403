import React, { Dispatch, useEffect, useState } from "react";
import { homeInfoConfig_Al } from "../../../config/HomeInfoConfig";
import { config_Al } from "../../../config/CekreziConfig";
import logo_text_only from "../../../assets/img/logo_text_only.png";
import global from "../../../global";
import contact from "../../contact";

function Slider(): JSX.Element {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [autoSlide, setAutoSlide] = useState<boolean>(true);

  useEffect(() => {
    let interval: any;
    if (autoSlide) {
      interval = setInterval(() => {
        setActiveSlideIndex((activeSlideIndex) => {
          if (
            activeSlideIndex ===
            homeInfoConfig_Al.mainSliderImages.length - 1
          ) {
            return 0;
          } else {
            return activeSlideIndex + 1;
          }
        });
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [autoSlide]);

  return (
    <div className="outer-container">
      <div className="inner-container slider">
        {homeInfoConfig_Al &&
          homeInfoConfig_Al.mainSliderImages.map((image, index) => (
            <div
              key={index}
              className="slider-image"
              style={
                index === activeSlideIndex
                  ? {
                      opacity: 1,
                    }
                  : {
                      opacity: 0,
                    }
              }
            >
              <global.Image src={image} />
            </div>
          ))}
        <div className="slider-overlay" />

        <div className="absolute w-full h-full left-0 top-0 flex justify-center items-center">
          <span className="font-bold text-2xl md:text-5xl xl:text-7xl text-white uppercase z-10">
            {config_Al && config_Al.slogan}
          </span>
          <global.Image
            className="object-contain w-16 lg:w-32 xl:w-56"
            style={{
              position: "absolute",
              left: "53%",
              top: "50%",
            }}
            src={logo_text_only}
          />
        </div>

        {homeInfoConfig_Al && (
          <SliderButtons
            activeIndex={activeSlideIndex}
            images={homeInfoConfig_Al.mainSliderImages}
            changeImage={setActiveSlideIndex}
            changeAutoSlide={setAutoSlide}
          />
        )}
        <contact.components.AsideContact autoHide={false} />
      </div>
    </div>
  );
}

export default Slider;

interface SliderButtonsProps {
  activeIndex: number;
  images: string[];
  changeImage: Dispatch<number>;
  changeAutoSlide: Dispatch<boolean>;
}

function SliderButtons({
  activeIndex,
  images,
  changeImage,
  changeAutoSlide,
}: SliderButtonsProps): JSX.Element {
  return (
    <div className="h-full flex flex-col justify-center ml-2 md:ml-12 xl:ml-24 z-10 overflow-y-auto">
      {images.map((image, index) => (
        <div
          key={index}
          className="w-16 h-16 md:w-32 md:h-32 lg:w-48 lg:h-48 flex justify-center items-center relative my-2 cursor-pointer"
        >
          <global.Image
            src={image}
            className="absolute left-0 top-0 w-full h-full object-cover"
          />
          <div
            onClick={() => {
              changeImage(index);
              changeAutoSlide(false);
            }}
            className={`absolute left-0 top-0 w-full h-full ${
              activeIndex !== index ? "bg-primary-700" : "bg-transparent"
            } hover:bg-gray-300 focus:bg-gray-300 opacity-50 transition duration-100 ease-linear`}
          />
        </div>
      ))}
    </div>
  );
}
