import React, { useContext } from "react";
import { UserContext } from "../../../provider";
import _string from "../../../config/localization/strLng";

function UniqueRegistrationCodeModal(): JSX.Element {
  const { registrationCode } = useContext(UserContext);

  return (
    <div
      className="modal micromodal-slide"
      id="registration-code-modal"
      aria-hidden="true"
    >
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close>
        <div className="modal__container" role="dialog" aria-modal="true">
          <main className="modal__content flex flex-col items-center py-8 px-4 text-center">
            <p className="mb-8 text-2xl text-green-500 font-bold">
              <span className="whitespace-pre-line">
                {_string.MESSAGES.registration_success}
              </span>
              <i className="las la-check text-green-500 text-3xl ml-4"></i>
            </p>
            <span className="text-white mb-8 px-10 whitespace-pre-line">
              {_string.MESSAGES.registration_code_message}
            </span>

            <div
              className="px-10 py-8 mb-4 rounded"
              style={{
                backgroundColor: "rgb(43 43 43)",
                letterSpacing: 2,
              }}
            >
              <span className="text-white">{registrationCode}</span>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default UniqueRegistrationCodeModal;
