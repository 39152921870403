import React, { useContext, useState } from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import * as yup from "yup";
import global from "../../../global";
import _string from "../../../config/localization/strLng";
import { fetchApi } from "../../../config/core/Api";
import { AxiosError, AxiosResponse } from "axios";
import { AppContext } from "../../../provider";

const contactValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  lastName: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  email: yup
    .string()
    .email(_string.ERRORS.invalid_email)
    .required(_string.ERRORS.required_field),
  phone: yup
    .string()
    .min(10, `10 ${_string.ERRORS.is_min_characters}`)
    .max(15, `15 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  details: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
});

interface ContactFormTypes {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  details: string;
}

function ContactForm(): JSX.Element {
  const { notify } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validateFormOnChange, setValidateFormOnChange] = useState<boolean>(
    false
  );

  function handleSubmit(values: ContactFormTypes, { resetForm }: any) {
    setIsLoading(true);
    fetchApi({
      url: "/contact",
      method: "POST",
      data: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        message: values.details,
        phone_number: values.phone,
      },
    })
      .then((r: AxiosResponse<any>) => {
        notify(_string.MESSAGES.request_submit_success, "success");
      })
      .catch((e: AxiosError) => {
        notify(_string.ERRORS.something_went_wrong, "error");
        console.log(e.message);
      })
      .finally(() => {
        setIsLoading(false);
        resetForm();
      });
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          details: "",
        }}
        validationSchema={contactValidationSchema}
        onSubmit={handleSubmit}
        validateOnChange={validateFormOnChange}
        validateOnBlur={validateFormOnChange}
      >
        {({ errors, touched, values }) => (
          <Form className="flex flex-col lg:flex-row lg:flex-wrap">
            <div className="flex w-full lg:w-1/2 mb-5 lg:mb-16 lg:pr-10">
              <Field name="firstName">
                {(fieldProps: FieldProps) => (
                  <FormInput
                    type="text"
                    placeholder={_string.LABELS.first_name.toUpperCase()}
                    {...fieldProps}
                  />
                )}
              </Field>
            </div>

            <div className="flex w-full lg:w-1/2 mb-5 lg:mb-16 lg:pl-10">
              <Field name="lastName">
                {(fieldProps: FieldProps) => (
                  <FormInput
                    type="text"
                    placeholder={_string.LABELS.last_name.toUpperCase()}
                    {...fieldProps}
                  />
                )}
              </Field>
            </div>

            <div className="flex w-full lg:w-1/2 mb-5 lg:mb-16 lg:pr-10">
              <Field name="phone">
                {(fieldProps: FieldProps) => (
                  <FormInput
                    type="number"
                    placeholder={`${_string.LABELS.tel.toUpperCase()} (${
                      _string.LABELS.optional
                    })`}
                    {...fieldProps}
                  />
                )}
              </Field>
            </div>

            <div className="flex w-full lg:w-1/2 mb-5 lg:mb-16 lg:pl-10">
              <Field name="email">
                {(fieldProps: FieldProps) => (
                  <FormInput
                    type="email"
                    placeholder={_string.LABELS.email.toUpperCase()}
                    {...fieldProps}
                  />
                )}
              </Field>
            </div>

            <div className="flex w-full lg:mb-16" style={{ minHeight: 300 }}>
              <Field name="details">
                {(fieldProps: FieldProps) => (
                  <FormInput
                    type="textarea"
                    placeholder={_string.LABELS.details.toUpperCase()}
                    {...fieldProps}
                  />
                )}
              </Field>
            </div>

            <div className="flex flex-col items-center lg:flex-row w-full pt-8 lg:pt-0">
              <global.Button
                loading={isLoading}
                horizontalPadding={12}
                htmlType="submit"
                onClick={() => {
                  setValidateFormOnChange(true);
                }}
              >
                {_string.ACTIONS.send.toUpperCase()}
              </global.Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ContactForm;

interface FormInputProps extends FieldProps {
  placeholder: string;
  type: "text" | "number" | "email" | "textarea";
}

export function FormInput({ placeholder, type, ...props }: FormInputProps) {
  return (
    <global.Input
      type={type}
      placeholder={placeholder}
      error={props.meta.error}
      {...props.field}
    />
  );
}
