import React, { useContext } from "react";
import _string from "../../config/localization/strLng";
import { AppContext } from "../../provider";

function PreNavigation(): JSX.Element {
  const { cekreziConfig } = useContext(AppContext);

  return (
    <div className="outer-container fluid bg-grayMatte">
      <div className="inner-container flex justify-center items-center text-white p-4">
        {cekreziConfig &&
          cekreziConfig.phoneNumber.map((number, index) => {
            return (
              <div key={index} className="flex items-center">
                {index === 0 && (
                  <div className="flex items-center">
                    <div className="flex items-center justify-center bg-white rounded-full w-5 h-5 mr-2">
                      <i className="las la-phone text-grayMatte" />
                    </div>
                    <span className="mr-3">{_string.LABELS.contact_now} :</span>
                  </div>
                )}
                <a
                  href={`tel:${number}`}
                  className="hover:text-primary-400 transition duration-100 ease-linear"
                >
                  {number}
                </a>
                {index !== cekreziConfig.phoneNumber.length - 1 && (
                  <div
                    style={{ width: 1 }}
                    className="h-4 bg-primary-600 mx-3"
                  />
                )}
              </div>
            );
          })}

        <div className="px-5" />

        {cekreziConfig &&
          cekreziConfig.email.map((email, index) => {
            return (
              <div key={index} className="flex items-center">
                {index === 0 && (
                  <div className="flex items-center">
                    <div className="flex items-center justify-center bg-white rounded-full w-5 h-5 mr-2">
                      <i className="las la-at text-grayMatte" />
                    </div>
                  </div>
                )}
                <a
                  href={`mailto:${email}`}
                  className="hover:text-primary-400 transition duration-100 ease-linear"
                >
                  {email}
                </a>
                {index !== cekreziConfig.email.length - 1 && (
                  <div
                    style={{ width: 1 }}
                    className="h-4 bg-primary-600 mx-3"
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default PreNavigation;
