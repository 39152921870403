import React from "react";
import _string from "../../config/localization/strLng";
import { GeneralLayout } from "../../layout";
import Layout from "./Layout";
import ProfileProvider from "./ProfileProvider";

function ProfileScreen(): JSX.Element {
  return (
    <GeneralLayout pageName={_string.PAGES.about_us}>
      <ProfileProvider>
        <Layout />
      </ProfileProvider>
    </GeneralLayout>
  );
}

export default ProfileScreen;
