import React from "react";
import { v4 as uuid } from "uuid";
import _string from "../../../config/localization/strLng";

interface BestSellingItemsProps {
  items: BestSellingItemTypes[];
}

export interface BestSellingItemTypes {
  name: string;
  units: string;
  price: string;
  priceChange: string | null;
  increasing: boolean | null;
}

function BestSellingItems({ items }: BestSellingItemsProps): JSX.Element {
  return (
    <div className="flex flex-grow flex-col">
      <div className="flex justify-between items-center py-4 px-10 rounded-t-sm lg:rounded-none bg-green-700">
        <h4 className="font-bold uppercase flex-1 text-center text-white">
          {_string.LABELS.best_seller_metals}
        </h4>
      </div>
      <div className="flex flex-wrap p-4 border border-t-0 border-solid border-gray-300">
        {items.length === 0 ? (
          <span className="px-8 py-4 text-center text-gray-700 w-full">
            {_string.MESSAGES.no_best_selling_items}
          </span>
        ) : (
          items.map((item) => (
            <div
              key={uuid()}
              className="flex flex-col justify-center items-center w-full sm:w-1/2 md:w-1/3 px-4 py-8 md:py-12 font-bold"
            >
              <span className="truncate mb-2 flex items-center">
                {item.name}
                {item.increasing !== null &&
                  (item.increasing ? (
                    <i className="las la-caret-up text-sm text-green-700 ml-2" />
                  ) : (
                    <i className="las la-caret-down text-sm text-red-700 ml-2" />
                  ))}
              </span>
              <span className="truncate mb-5">{item.units}</span>
              <span className="truncate mb-5">{item.price}</span>
              {item.priceChange && (
                <span
                  className={`truncate ${
                    item.increasing ? "text-green-800" : "text-red-600"
                  }`}
                >
                  {item.priceChange}
                </span>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default BestSellingItems;
