import React, { useContext, useState } from "react";
import navigation from ".";
// import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import _string from "../../config/localization/strLng";
import { AppContext } from "../../provider";

// let reCaptchaKey = "6Lf3zvwZAAAAAINSZv2BDzmjsxKClCwQ9hKwcaI5";

function AuthModal(): JSX.Element {
  const { isMd } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState<"login" | "register">("login");

  return (
    <div className="modal micromodal-slide" id="auth-modal" aria-hidden="true">
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close>
        <div
          className="modal__container text-base"
          role="dialog"
          aria-modal="true"
        >
          <header className="modal__header h-16 lg:h-20">
            <navigation.components.ThemedButton
              action={() => {
                setActiveTab("login");
              }}
              css={{
                width: "47%",
                overflow: "visible",
                zIndex: 1,
                outline: "none",
                border: 0,
              }}
              className={`${activeTab === "login" ? "" : "bg-grayMatte"}`}
              sideCss={{
                right: isMd ? "-1rem" : "-1.5rem",
                transformOrigin: "left center",
                backgroundColor: "inherit",
              }}
              sideClass="bg-grayMatte"
            >
              <span className="lg:text-lg">{_string.ACTIONS.login}</span>
            </navigation.components.ThemedButton>
            <navigation.components.ThemedButton
              action={() => {
                setActiveTab("register");
              }}
              css={{
                width: "53%",
                outline: "none",
                border: 0,
                paddingLeft: "2rem",
              }}
              className={`${activeTab === "register" ? "" : "bg-grayMatte"}`}
              sideCss={{ display: "none" }}
            >
              <span className="lg:text-lg">{_string.ACTIONS.register}</span>
            </navigation.components.ThemedButton>
          </header>
          <main className="modal__content flex">
            {activeTab === "login" ? (
              <navigation.components.LoginModalForm />
            ) : (
              <navigation.components.RegisterModalForm />
            )}
          </main>
        </div>
      </div>
    </div>
  );
}

export default AuthModal;
