import React, { Dispatch } from "react";
import _string from "../config/localization/strLng";
import { PaginationPaths } from "../Types";

interface PaginationLoadMoreProps {
  pagination: PaginationPaths;
  setPagination: Dispatch<PaginationPaths>;
}

function PaginationLoadMore({
  pagination,
  setPagination,
}: PaginationLoadMoreProps): JSX.Element {
  function handleNext() {
    if (pagination.nextPageUrl) {
      setPagination({
        ...pagination,
        currentPath: pagination.nextPageUrl,
      });
    }
  }

  return (
    <div className="w-full flex justify-center mt-3">
      <button
        onClick={handleNext}
        disabled={!pagination.nextPageUrl}
        type="button"
        className={`px-4 py-2 flex items-center justify-center border border-solid border-gray-400 rounded ${
          !pagination.nextPageUrl ? "text-gray-400" : ""
        }`}
      >
        <span>{_string.ACTIONS.load_more}</span>
      </button>
    </div>
  );
}

export default PaginationLoadMore;
