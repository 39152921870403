import AboutUsScreen from "./about/AboutUsScreen";
import ContactScreen from "./contact/ContactScreen";
import HomeScreen from "./home/HomeScreen";
import LoginScreen from "./login/LoginScreen";
import RegisterScreen from "./register/RegisterScreen";
import ServicesScreen from "./service/ServicesScreen";
import ProfileScreen from "./profile/ProfileScreen";

export default {
  AboutUsScreen,
  ContactScreen,
  HomeScreen,
  LoginScreen,
  RegisterScreen,
  ServicesScreen,
  ProfileScreen,
};
