import { AxiosError, AxiosResponse } from "axios";
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import { fetchApi, FetchApiProps } from "../../config/core/Api";
import _string from "../../config/localization/strLng";
import { AppContext, UserContext } from "../../provider";
import { PaginationPaths, UserTransactionsResponse } from "../../Types";
import { formateDate, TableDataTypes } from "../home/components/ProductPrices";
import { BestSellingItemTypes } from "./components/BestSellingItems";

interface ProfileProviderTypes {
  children: JSX.Element;
}

interface ProfileContextTypes {
  isLoading: boolean;
  transactions: TableDataTypes[];
  bestSellingItems: BestSellingItemTypes[];
  transactionsPagination: PaginationPaths;
  setTransactionsPagination: Dispatch<PaginationPaths>;
}

const PROFILE_CONTEXT_INITIAL_VALUES = {
  isLoading: false,
  transactions: [],
  bestSellingItems: [],
  transactionsPagination: {
    nextPageUrl: "",
    prevPageUrl: "",
    currentPath: "/transactions",
  },
  setTransactionsPagination: (transactionsPagination: PaginationPaths) =>
    undefined,
};

export const ProfileContext = createContext<ProfileContextTypes>({
  ...PROFILE_CONTEXT_INITIAL_VALUES,
});

function ProfileProvider({ children }: ProfileProviderTypes): JSX.Element {
  const { notify } = useContext(AppContext);
  const { handleAuthFailure, jwt_token } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<TableDataTypes[]>([]);
  const [transactionsPagination, setTransactionsPagination] = useState<
    PaginationPaths
  >({
    nextPageUrl: "",
    prevPageUrl: "",
    currentPath: "/transactions",
  });
  const [bestSellingItems, setBestSellingItems] = useState<
    BestSellingItemTypes[]
  >([]);

  const getTransactions = useCallback(
    (statePagination: PaginationPaths, path: string) => {
      setIsLoading(true);
      fetchApi(
        Object.assign(
          {
            method: "GET",
            url: path,
            headers: { Authorization: `Bearer ${jwt_token}` },
          },
          path.includes("http")
            ? {
                fullUrl: true,
              }
            : {}
        ) as FetchApiProps
      )
        .then((r: AxiosResponse<UserTransactionsResponse>) => {
          let tempTransactions: TableDataTypes[] = [];
          let tempBestSellingItems: BestSellingItemTypes[] = [];

          if (r.status === 200) {
            const { mostUsedMetals, transactions } = r.data;

            transactions.data.forEach((transaction) => {
              tempTransactions.push({
                price: {
                  headLabel: _string.LABELS.total,
                  // TODO: Change currency later
                  data: `${transaction.price} ALL`,
                },
                product: {
                  headLabel: _string.LABELS.metals,
                  data: transaction.description,
                },
                updated_at: {
                  headLabel: _string.LABELS.updated_at,
                  data: formateDate(moment(transaction.updated_at).toDate()),
                },
              });
            });

            Object.keys(mostUsedMetals).forEach((metal) => {
              tempBestSellingItems.push({
                name: metal,
                units: "ALL/KG",
                price: `${mostUsedMetals[metal]} ALL`,
                priceChange: null,
                increasing: null,
              });
            });

            const { next_page_url, prev_page_url } = transactions;

            let nextUrl: string = "";
            let prevUrl: string = "";

            if (next_page_url) {
              nextUrl = next_page_url;
            } else {
              nextUrl = "";
            }

            if (prev_page_url) {
              prevUrl = prev_page_url;
            } else {
              prevUrl = "";
            }

            setTransactionsPagination({
              ...statePagination,
              currentPath: path,
              nextPageUrl: nextUrl,
              prevPageUrl: prevUrl,
            });
          }

          setBestSellingItems(tempBestSellingItems);
          setTransactions([...tempTransactions]);

          handleAuthFailure(r);
        })
        .catch((e: AxiosError) => {
          console.log(e.message);
          notify(_string.ERRORS.something_went_wrong, "error");
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [jwt_token, handleAuthFailure, notify]
  );

  useEffect(() => {
    if (jwt_token && transactionsPagination.currentPath) {
      getTransactions(
        transactionsPagination,
        transactionsPagination.currentPath
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsPagination.currentPath, jwt_token, getTransactions]);

  const providerValue = {
    isLoading,
    transactions,
    bestSellingItems,
    transactionsPagination,
    setTransactionsPagination,
  };

  return (
    <ProfileContext.Provider value={providerValue}>
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
