import React, { useContext, useState } from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import * as yup from "yup";
import _string from "../../../config/localization/strLng";
import { fetchApi } from "../../../config/core/Api";
import Storage from "../../../config/core/Storage";
import { AxiosError, AxiosResponse } from "axios";
import global from "../../../global";
import MicroModal from "micromodal";
import { AppContext, UserContext } from "../../../provider";
import { UserRegistrationResponse } from "../../../Types";

interface LoginFormFields {
  verificationCode: string;
  rememberLogin: boolean;
}

type LoginStateType = "individual" | "company";

interface LoginTypesConfig {
  label: string;
  value: LoginStateType;
}

const loginValidationSchema = yup.object().shape({
  verificationCode: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  rememberLogin: yup.boolean(),
});

function LoginModalForm(): JSX.Element {
  const { notify } = useContext(AppContext);
  const { setUserInfo, setJwtToken } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validateFormOnChange, setValidateFormOnChange] = useState<boolean>(
    false
  );

  function handleLogin(values: LoginFormFields, { resetForm }: any) {
    const { verificationCode, rememberLogin } = values;

    setIsLoading(true);
    fetchApi({
      url: "/login",
      method: "POST",
      data: {
        code: verificationCode,
      },
    })
      .then((r: AxiosResponse<UserRegistrationResponse>) => {
        if (r.data.user) {
          notify(_string.MESSAGES.login_success);
          MicroModal.close("auth-modal");
          setJwtToken(r.data.access_token);
          setTimeout(() => {
            setUserInfo(r.data.user);
          }, 300);
          if (rememberLogin) {
            handleRememberUser(r.data.access_token);
          }
        } else {
          notify(_string.ERRORS.invalid_user, "error");
        }
      })
      .catch((e: AxiosError) => {
        notify(_string.ERRORS.login_failed, "error");
        console.log(e.message);
      })
      .finally(() => {
        setIsLoading(false);
        resetForm();
      });
  }

  function handleRememberUser(token: string) {
    Storage.setItem("auth_token", token);
  }

  return (
    <div className="flex flex-grow flex-col items-center px-3 pb-6 pt-10 md:pb-12 md:pt-32 md:px-32">
      <div>
        <Formik
          initialValues={{
            verificationCode: "",
            rememberLogin: false,
          }}
          validationSchema={loginValidationSchema}
          onSubmit={handleLogin}
          validateOnChange={validateFormOnChange}
          validateOnBlur={validateFormOnChange}
        >
          {({ errors, touched, values }) => (
            <Form className="flex flex-col md:flex-row md:flex-wrap">
              <div className="flex w-full">
                <Field name="verificationCode">
                  {(fieldProps: FieldProps) => (
                    <FormInput
                      type="text"
                      placeholder={_string.LABELS.verification_code.toUpperCase()}
                      {...fieldProps}
                    />
                  )}
                </Field>
              </div>

              <div className="flex w-full mb-8 md:mb-20">
                <Field name="rememberLogin">
                  {(fieldProps: FieldProps) => {
                    const { field, form } = fieldProps;
                    return (
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          className="input-checked"
                          type="checkbox"
                          hidden
                          value={field.value}
                          onChange={() => {
                            form.setFieldValue("rememberLogin", !field.value);
                          }}
                        />
                        <label
                          htmlFor="remember-me"
                          className="flex items-center cursor-pointer"
                        >
                          <span className="w-4 h-4 border border-solid border-gray-400 rounded-sm cursor-pointer input-box"></span>
                          <span className="uppercase text-white ml-3">
                            {_string.ACTIONS.remember_me}
                          </span>
                        </label>
                      </div>
                    );
                  }}
                </Field>
              </div>

              <div className="flex flex-col items-center justify-center md:flex-row w-full md:mb-4">
                <global.Button
                  loading={isLoading}
                  verticalPadding={3}
                  horizontalPadding={16}
                  htmlType="submit"
                  bgColor="bg-white text-gray-800"
                  hoverBgColor="hover:bg-green-700 hover:text-white"
                  onClick={() => {
                    setValidateFormOnChange(true);
                  }}
                >
                  {_string.ACTIONS.send.toUpperCase()}
                </global.Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default LoginModalForm;

interface FormInputProps extends FieldProps {
  placeholder: string;
  type: "text" | "number" | "email" | "textarea";
}

export function FormInput({ placeholder, type, ...props }: FormInputProps) {
  return (
    <global.InputV2
      type={type}
      placeholder={placeholder}
      error={props.meta.error}
      {...props.field}
    />
  );
}
