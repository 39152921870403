import React, { useContext, useState } from "react";
import { v4 as uuid } from "uuid";
import { Formik, Form, Field, FieldProps } from "formik";
import * as yup from "yup";
import _string from "../../../config/localization/strLng";
import { fetchApi } from "../../../config/core/Api";
import { AxiosError, AxiosResponse } from "axios";
import global from "../../../global";
import { FormInput } from "./LoginModalForm";
import MicroModal from "micromodal";
import { AppContext, UserContext } from "../../../provider";
import { UserRegistrationResponse } from "../../../Types";

interface RegisterFormFields {
  firstName: string;
  lastName: string;
  phoneNumber: number | "";
  agreeTerms: boolean;
}

type RegisterStateType = "individual" | "company";

interface RegisterTypesConfig {
  label: string;
  value: RegisterStateType;
}

const registerTypesConfig: RegisterTypesConfig[] = [
  {
    label: _string.ACTIONS.individual,
    value: "individual",
  },
  {
    label: _string.ACTIONS.company,
    value: "company",
  },
];

const registerValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  lastName: yup
    .string()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(50, `50 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  phoneNumber: yup
    .number()
    .min(2, `2 ${_string.ERRORS.is_min_characters}`)
    .max(9999999999999, `14 ${_string.ERRORS.is_max_characters}`)
    .required(_string.ERRORS.required_field),
  agreeTerms: yup.boolean().oneOf([true]),
});

function RegisterModalForm(): JSX.Element {
  const { notify } = useContext(AppContext);
  const { setRegistrationCode } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registerType, setRegisterType] = useState<RegisterStateType>(
    "individual"
  );
  const [validateFormOnChange, setValidateFormOnChange] = useState<boolean>(
    false
  );

  function handleRegister(values: RegisterFormFields, { resetForm }: any) {
    const { firstName, lastName, phoneNumber } = values;
    setIsLoading(true);
    fetchApi({
      url: "/register",
      method: "POST",
      data: {
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        type: registerType,
      },
    })
      .then((r: AxiosResponse<UserRegistrationResponse>) => {
        setRegistrationCode(r.data.code);
        MicroModal.close("auth-modal");
        setTimeout(() => {
          MicroModal.show("registration-code-modal");
        }, 300);
      })
      .catch((e: AxiosError) => {
        notify(_string.ERRORS.register_failed, "error");
        console.log(e.message);
      })
      .finally(() => {
        setIsLoading(false);
        resetForm();
      });
  }

  return (
    <div className="flex flex-grow flex-col items-center px-3 py-6 md:py-12 md:px-32">
      <div className="flex items-center mb-8 md:mb-20">
        {registerTypesConfig.map((type, index) => {
          return (
            <div className="flex items-center" key={uuid()}>
              <button
                onClick={() => {
                  setRegisterType(type.value);
                }}
                className={`py-2 px-3 mx-2 focus:outline-none transition duration-100 ease-linear ${
                  registerType === type.value ? "text-green-700" : "text-white"
                }`}
                type="button"
              >
                {type.label}
              </button>
              {registerTypesConfig.length - 1 !== index && (
                <div className="h-4 bg-white" style={{ width: 2 }} />
              )}
            </div>
          );
        })}
      </div>
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            phoneNumber: "",
            agreeTerms: false,
          }}
          validationSchema={registerValidationSchema}
          onSubmit={handleRegister}
          validateOnChange={validateFormOnChange}
          validateOnBlur={validateFormOnChange}
        >
          {({ setValues, errors, touched, values, ...formProps }) => {
            return (
              <Form className="flex flex-col md:flex-row md:flex-wrap">
                <div className="flex w-full">
                  <Field name="firstName">
                    {(fieldProps: FieldProps) => (
                      <FormInput
                        type="text"
                        placeholder={_string.LABELS.first_name}
                        {...fieldProps}
                      />
                    )}
                  </Field>
                </div>

                <div className="flex w-full">
                  <Field name="lastName">
                    {(fieldProps: FieldProps) => (
                      <FormInput
                        type="text"
                        placeholder={_string.LABELS.last_name}
                        {...fieldProps}
                      />
                    )}
                  </Field>
                </div>

                <div className="flex w-full">
                  <Field name="phoneNumber">
                    {(fieldProps: FieldProps) => (
                      <FormInput
                        type="number"
                        placeholder={_string.LABELS.phone_number}
                        {...fieldProps}
                      />
                    )}
                  </Field>
                </div>

                <div className="flex w-full mb-8 md:mb-20">
                  <Field name="agreeTerms">
                    {(fieldProps: FieldProps) => {
                      const { field, form, meta } = fieldProps;
                      return (
                        <div className="flex ">
                          <input
                            id="remember-me"
                            className="input-checked"
                            type="checkbox"
                            hidden
                            value={field.value}
                            onChange={() => {
                              form.setFieldValue("agreeTerms", !field.value);
                            }}
                          />
                          <label
                            htmlFor="remember-me"
                            className="flex cursor-pointer"
                          >
                            <span
                              className={`
                            ${meta.error ? "border-red-600" : "border-gray-400"}
                            w-4 h-4 flex-shrink-0 mt-3 border border-solid  rounded-sm cursor-pointer input-box
                            `}
                            ></span>
                            <div className="flex flex-wrap">
                              <span
                                className={`
                              ${meta.error ? "text-red-600" : "text-white"}
                              uppercase ml-3`}
                              >
                                {_string.MESSAGES.in_order_to_register}
                              </span>
                              <a
                                href="#/"
                                className="ml-3 text-primary-600 hover:text-primary-800 mr-2 transition duration-100 ease-linear"
                              >
                                {_string.LABELS.terms_conditions}
                              </a>
                            </div>
                          </label>
                        </div>
                      );
                    }}
                  </Field>
                </div>

                <div className="flex flex-col items-center justify-center md:flex-row w-full md:mb-4">
                  <global.Button
                    loading={isLoading}
                    verticalPadding={3}
                    horizontalPadding={16}
                    htmlType="submit"
                    bgColor="bg-white text-gray-800"
                    hoverBgColor="hover:bg-green-700 hover:text-white"
                    onClick={() => {
                      setValidateFormOnChange(true);
                    }}
                  >
                    {_string.ACTIONS.send.toUpperCase()}
                  </global.Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default RegisterModalForm;
