import React from "react";
import { SwitchNavigation } from "../navigation";
import provider from "../provider";

function RootContainer() {
  return (
    <provider.AppProvider>
      <provider.UserProvider>
        <SwitchNavigation />
      </provider.UserProvider>
    </provider.AppProvider>
  );
}

export default RootContainer;
