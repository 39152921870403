import { CekreziConfig } from "../Types";

export const config_Al: CekreziConfig = {
  name: "CekreziAl",
  slogan: "All you need",
  phoneNumber: ["+355 67 20 06 505", "+355 69 20 65 262"],
  email: ["info@cekrezi.al", "metal@cekrezi.al"],
  address: [
    {
      label: "Filiali Qendror: Vaqarr",
      location: "SH56, Vaqar, Tirana, ALbania",
      phoneNumber: ["+355 67 20 06 505", "+355 69 20 65 262"],
      email: ["info@cekrezi.al"],
    },
    {
      label: "Filiali Qendror: Fier",
      location: "SH73, Portez, Fier, ALbania",
      phoneNumber: ["+355 67 20 06 505"],
      email: ["info@cekrezi.al"],
    },
  ],
};
