import components from "./components";
import PreNavigation from "./PreNavigation";
import HomeNavigation from "./HomeNavigation";
import GuestActions from "./GuestActions";
import HomeActions from "./HomeActions";
import MobileNavigation from "./MobileNavigation";
import AuthModal from "./AuthModal";
import CheckRole from "./CheckRole";

export default {
  components,
  PreNavigation,
  HomeNavigation,
  GuestActions,
  HomeActions,
  MobileNavigation,
  AuthModal,
  CheckRole,
};
