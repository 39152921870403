import MicroModal from "micromodal";
import React, { useContext, useState } from "react";
import { fetchApi } from "../../../config/core/Api";
import _string from "../../../config/localization/strLng";
import global from "../../../global";
import * as yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";
import { AppContext } from "../../../provider";

const SUPPORTED_DOCUMENT_FORMATS = ["application/pdf"];

const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

interface UnregisterCarFormTypes {
  ownershipProofFile: File | "";
  drivingLicenseFile: File | "";
  carImageFile: File | "";
}

let unregisterCarSchema = yup.object().shape({
  ownershipProofFile: yup
    .mixed()
    .required(_string.ERRORS.required_field)
    .test(
      "fileFormat",
      _string.ERRORS.unsupported_format,
      (value) => value && SUPPORTED_DOCUMENT_FORMATS.includes(value.type)
    ),
  drivingLicenseFile: yup
    .mixed()
    .required(_string.ERRORS.required_field)
    .test(
      "fileFormat",
      _string.ERRORS.unsupported_format,
      (value) => value && SUPPORTED_DOCUMENT_FORMATS.includes(value.type)
    ),
  carImageFile: yup
    .mixed()
    .required(_string.ERRORS.required_field)
    .test(
      "fileFormat",
      _string.ERRORS.unsupported_format,
      (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
    ),
});

function UnregisterCardModalForm(): JSX.Element {
  const { notify } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [validateFormOnChange, setValidateFormOnChange] = useState<boolean>(
    false
  );
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  function handleSubmit(values: UnregisterCarFormTypes, { resetForm }: any) {
    setIsValidForm(true);

    var dataFormData = new FormData();

    dataFormData.append("car_photo", values.carImageFile);
    dataFormData.append("ownership_doc", values.ownershipProofFile);
    dataFormData.append("license", values.drivingLicenseFile);

    setIsLoading(true);
    fetchApi({
      url: "/valuate/car",
      method: "POST",
      data: dataFormData,
    })
      .then((r) => {
        notify(_string.MESSAGES.request_submit_success, "success");

        setTimeout(() => {
          setIsValidForm(false);
          MicroModal.close("unregister-car-modal");
        }, 3000);
      })
      .catch((e) => {
        notify(_string.ERRORS.register_failed, "error");
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
        resetForm();
      });
  }

  return (
    <div
      className="modal micromodal-slide"
      id="unregister-car-modal"
      aria-hidden="true"
    >
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close>
        <div className="modal__container" role="dialog" aria-modal="true">
          <header className="flex flex-col p-5">
            <button
              onClick={() => {
                MicroModal.close("unregister-car-modal");
              }}
              className="self-end"
              aria-label="Close modal"
              data-micromodal-close
            >
              <i className="text-white text-2xl las la-times"></i>
            </button>
          </header>
          <main className="modal__content flex flex-col items-center justify-center pt-4 md:pt-6 pb-12 px-4 md:px-20">
            <Formik
              initialValues={{
                ownershipProofFile: "",
                drivingLicenseFile: "",
                carImageFile: "",
              }}
              validationSchema={unregisterCarSchema}
              onSubmit={handleSubmit}
              validateOnChange={validateFormOnChange}
              validateOnBlur={validateFormOnChange}
            >
              {({ errors, touched, values }) => {
                return (
                  <Form className="flex flex-col items-center">
                    <div className="flex flex-col md:flex-row justify-center w-full mb-10 md:mb-20">
                      <Field name="ownershipProofFile">
                        {(fieldProps: FieldProps) => (
                          <>
                            <input
                              type="file"
                              id="ownership-proof-file"
                              accept="application/pdf"
                              onChange={(e) => {
                                if (e.target.files?.length) {
                                  fieldProps.form.setFieldValue(
                                    "ownershipProofFile",
                                    e.target.files[0]
                                  );
                                }
                              }}
                              hidden
                            />
                            <label
                              className={`relative flex flex-1 justify-center text-center items-center px-6 py-4 mb-4 md:mb-0 md:mr-3 text-white cursor-pointer bg-lightGrayMatte hover:bg-green-800 rounded-sm transition duration-100 ease-linear`}
                              htmlFor="ownership-proof-file"
                            >
                              <span className="uppercase mr-3 font-light">
                                {_string.LABELS.ownership_proof}
                              </span>
                              {isValidForm ? (
                                <i className="bg-white la-check-circle las p-1 rounded-full text-2xl text-green-800"></i>
                              ) : (
                                <i className="text-2xl las la-paperclip"></i>
                              )}
                              {fieldProps.meta.error && (
                                <span
                                  style={{ bottom: -30 }}
                                  className="absolute left-0 px-2 py-1 text-center text-red-600 w-full truncate"
                                >
                                  {fieldProps.meta.error}
                                </span>
                              )}
                            </label>
                          </>
                        )}
                      </Field>

                      <Field name="drivingLicenseFile">
                        {(fieldProps: FieldProps) => (
                          <>
                            <input
                              type="file"
                              id="driving-license-file"
                              accept="application/pdf"
                              onChange={(e) => {
                                if (e.target.files?.length) {
                                  fieldProps.form.setFieldValue(
                                    "drivingLicenseFile",
                                    e.target.files[0]
                                  );
                                }
                              }}
                              hidden
                            />
                            <label
                              className={`relative flex flex-1 justify-center text-center items-center px-6 py-4 md:ml-3 text-white cursor-pointer bg-lightGrayMatte hover:bg-green-800 rounded-sm transition duration-100 ease-linear`}
                              htmlFor="driving-license-file"
                            >
                              <span className="uppercase mr-3 font-light">
                                {_string.LABELS.driving_license}
                              </span>
                              {isValidForm ? (
                                <i className="bg-white la-check-circle las p-1 rounded-full text-2xl text-green-800"></i>
                              ) : (
                                <i className="text-2xl las la-paperclip"></i>
                              )}
                              {fieldProps.meta.error && (
                                <span
                                  style={{ bottom: -30 }}
                                  className="absolute left-0 px-2 py-1 text-center text-red-600 w-full truncate"
                                >
                                  {fieldProps.meta.error}
                                </span>
                              )}
                            </label>
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="flex justify-center w-full mb-8">
                      <Field name="carImageFile">
                        {(fieldProps: FieldProps) => (
                          <>
                            <input
                              type="file"
                              id="car-image-file"
                              accept="image/jpg,image/jpeg,image/gif,image/png"
                              onChange={(e) => {
                                if (e.target.files?.length) {
                                  fieldProps.form.setFieldValue(
                                    "carImageFile",
                                    e.target.files[0]
                                  );
                                }
                              }}
                              hidden
                            />
                            <label
                              htmlFor="car-image-file"
                              className={`relative flex items-center justify-center w-full md:w-auto px-6 py-4 rounded-sm bg-white text-grayMatte hover:bg-green-800 hover:text-white cursor-pointer`}
                            >
                              {isValidForm ? (
                                <i className="bg-white la-check-circle las p-1 rounded-full text-2xl text-green-800"></i>
                              ) : (
                                <i className="text-2xl las la-image mr-3"></i>
                              )}
                              <span>{_string.ACTIONS.upload_photo}</span>
                              {fieldProps.meta.error && (
                                <span
                                  style={{ bottom: -30 }}
                                  className="absolute left-0 px-2 py-1 text-center text-red-600 w-full truncate"
                                >
                                  {fieldProps.meta.error}
                                </span>
                              )}
                            </label>
                          </>
                        )}
                      </Field>
                    </div>

                    <global.Button
                      loading={isLoading}
                      verticalPadding={3}
                      horizontalPadding={16}
                      htmlType="submit"
                      bgColor="w-full md:w-auto bg-green-800 text-white"
                      hoverBgColor="hover:bg-white hover:text-grayMatte"
                      onClick={() => {
                        setValidateFormOnChange(true);
                      }}
                    >
                      {_string.ACTIONS.send.toUpperCase()}
                    </global.Button>
                  </Form>
                );
              }}
            </Formik>
          </main>
        </div>
      </div>
    </div>
  );
}

export default UnregisterCardModalForm;
