import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { homeInfoConfig_Al } from "../../../config/HomeInfoConfig";
import { AppContext } from "../../../provider";
import home from "../../home";
import serviceComp from "../../service";

function ServiceSteps(): JSX.Element {
  const history = useHistory();
  const { isXl } = useContext(AppContext);

  const [hashList, setHashList] = useState<string[]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (homeInfoConfig_Al && homeInfoConfig_Al.servicesSteps.length) {
      let tempHash: string[] = [];

      homeInfoConfig_Al.servicesSteps.forEach((service) => {
        tempHash.push(service.sectionId);
      });

      setHashList(tempHash);
    }
  }, []);

  useEffect(() => {
    hashList.forEach((hash, index) => {
      if (history.location.hash === hash) {
        setSelectedItemIndex(index);
      }
    });
  }, [hashList, history.location.hash]);

  return (
    <div className="flex flex-1 flex-col items-center xl:items-start">
      {homeInfoConfig_Al &&
        homeInfoConfig_Al.servicesSteps.map((service, index) => (
          <div
            key={index}
            className={`${
              index !== homeInfoConfig_Al.servicesSteps.length - 1 &&
              "md:pb-12 xl:pb-32"
            } h-auto w-full p-4 xl:px-12 mb-6 xl:mb-0`}
            style={{ maxWidth: 800 }}
          >
            <div className="flex flex-col">
              <home.components.ServiceItem
                {...service}
                isActive={!!(history.location.hash === service.sectionId)}
                isMobileCol={true}
                goToTop={true}
              />
              {isXl && selectedItemIndex === index && (
                <div className="mt-5">
                  <serviceComp.components.ProductList />
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

export default ServiceSteps;
