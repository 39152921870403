import React, { useContext } from "react";
import { homeInfoConfig_Al } from "../../../config/HomeInfoConfig";
import global from "../../../global";
import triangle_shape from "../../../assets/img/triangle_shape.svg";
import { AppContext } from "../../../provider";

function Industry_Marketing(): JSX.Element {
  const { isXl } = useContext(AppContext);
  return (
    <div className="outer-container">
      <div
        className="inner-container flex flex-col xl:flex-row my-6 xl:mt-20 xl:mb-6"
        style={isXl ? { maxWidth: 800 } : {}}
      >
        {homeInfoConfig_Al && homeInfoConfig_Al.industry_marketing && (
          <>
            <div className="flex flex-col w-full xl:w-1/2 xl:py-16 px-4 mb-4 xl:mb-0">
              <h3 className="text-2xl xl:text-3xl font-bold mb-4 uppercase">
                {homeInfoConfig_Al.industry_marketing.title}
              </h3>
              <p className="text-xs md:text-base font-light leading-6">
                {homeInfoConfig_Al.industry_marketing.description}
              </p>
            </div>

            <div className="flex w-full xl:w-1/2 pl-16 xl:pl-32 px-4 xl:px-0 h-32 xl:h-64">
              <div className="flex flex-1 relative">
                <global.Image
                  className="flex-1 object-cover"
                  src={homeInfoConfig_Al.industry_marketing.image}
                  alt=""
                />
                <div className="h-full absolute left-0 top-0">
                  <global.Image
                    className="w-20 xl:w-48"
                    src={triangle_shape}
                    alt=""
                    style={{
                      filter: "grayscale(1) brightness(5)",
                    }}
                  />
                </div>
                <div className="h-full absolute left-0 top-0">
                  <global.Image
                    className="w-16 ml-4 xl:w-32 xl:ml-16"
                    src={triangle_shape}
                    alt=""
                  />
                </div>
                <div className="absolute left-0 bottom-0">
                  <global.Image
                    className="h-12 xl:h-16"
                    src={triangle_shape}
                    alt=""
                    style={{
                      transform: "rotate(180deg) translateX(100%)",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Industry_Marketing;
