import React, { useCallback, useContext, useEffect, useRef } from "react";
import module from "../module";
import Helmet from "react-helmet";
import { AppContext } from "../provider";

interface GeneralLayoutProps {
  pageName: string;
  children: JSX.Element;
}

const GeneralLayout = ({ pageName, children }: GeneralLayoutProps) => {
  const preNavRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const { cekreziConfig, isLg } = useContext(AppContext);

  const handleElem = useCallback(() => {
    if (preNavRef.current && navRef.current && bodyRef.current) {
      let preNavHeight = preNavRef.current.clientHeight;
      let navHeight = navRef.current.clientHeight;

      preNavRef.current.classList.add("fixed", "w-full", "z-50");

      navRef.current.classList.add("fixed", "w-full", "z-50");

      if (isLg) {
        editHeights(0, navHeight);
      } else {
        editHeights(preNavHeight, preNavHeight + navHeight);
      }
    }
  }, [isLg]);

  useEffect(() => {
    window.addEventListener("load", handleElem);
    window.addEventListener("resize", handleElem);

    return () => {
      window.removeEventListener("load", handleElem);
      window.removeEventListener("resize", handleElem);
    };
  }, [handleElem]);

  function editHeights(navigationTopOffset: number, bodyTopOffset: number) {
    if (preNavRef.current && navRef.current && bodyRef.current) {
      navRef.current.style.top = `${navigationTopOffset}px`;
      bodyRef.current.style.paddingTop = `${bodyTopOffset}px`;
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {pageName} | {cekreziConfig && cekreziConfig.name}
        </title>
      </Helmet>
      <module.navigation.CheckRole />
      <div ref={preNavRef} className="hidden lg:block">
        <module.navigation.PreNavigation />
      </div>
      <div ref={navRef} className="w-full z-50">
        <module.navigation.HomeNavigation />
      </div>
      <div ref={bodyRef}>{children}</div>
      <module.footer.FooterLayout />
    </>
  );
};

export default GeneralLayout;
