import React from "react";

interface ButtonProps {
  children: JSX.Element;
  onClick?: (arg?: any) => any;
  type?: "primary" | "outlined";
  bgColor?: string;
  hoverBgColor?: string;
  loading?: boolean;
  disabled?: boolean;
  htmlType?: "button" | "submit";
  horizontalPadding?: number;
  verticalPadding?: number;
  extraCssClass?: string;
}

function Button({
  children,
  type = "primary",
  onClick = () => undefined,
  bgColor = "bg-primary-700",
  hoverBgColor = "hover:bg-primary-900",
  loading = false,
  disabled = false,
  htmlType = "button",
  horizontalPadding = 6,
  verticalPadding = 3,
  extraCssClass = "",
}: ButtonProps): JSX.Element {
  return (
    <button
      type={htmlType}
      onClick={onClick}
      className={`${
        loading || disabled ? "opacity-75" : ""
      } ${bgColor} ${hoverBgColor} rounded py-${verticalPadding} px-${horizontalPadding} text-white
      flex items-center justify-center ${extraCssClass} hover:shadow transition duration-100 ease-linear
      `}
      disabled={loading || disabled}
    >
      {loading && (
        <i className="las la-circle-notch animate-spin text-xl mr-2" />
      )}
      <span>{children}</span>
    </button>
  );
}

export default Button;
