import React, { useContext } from "react";
import { AppContext } from "../../../provider";
import footer from "../../../module/footer";
import map_screenshot_vaqarr from "../../../assets/img/map_screenshot_vaqarr.png";
import map_screenshot_fier from "../../../assets/img/map_screenshot_fier.png";
import contact from "../../contact";

let images: string[] = [map_screenshot_vaqarr, map_screenshot_fier];

function Locations(): JSX.Element {
  const { cekreziConfig } = useContext(AppContext);

  return (
    <div className="relative flex flex-col flex-1">
      {cekreziConfig &&
        cekreziConfig.address.map((address, index) => (
          <div
            key={index}
            className="flex flex-1 relative lg:justify-end lg:items-end lg:pr-16"
          >
            <img
              className="absolute w-full h-full top-0 left-0 object-cover"
              src={images[index]}
              alt=""
            />

            <div
              className="absolute w-full h-full top-0 left-0 bg-grayMatte"
              style={{ opacity: 0.9 }}
            />

            <div className="z-10">
              <footer.components.FooterAddress address={address} />
            </div>
          </div>
        ))}
      <contact.components.AsideContact />
    </div>
  );
}

export default Locations;
