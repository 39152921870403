import Slider from "./Slider";
import ServicesSteps from "./ServicesSteps";
import ContactBanner from "./ContactBanner";
import ServiceItem from "./ServiceItem";
import Industry_Marketing from "./Industry_Marketing";
import ActionCards from "./ActionCards";
import ProductPrices, { PriceTable } from "./ProductPrices";
import CollectionProcess from "./CollectionProcess";
import SellProductModalForm from "./SellProductModalForm";
import UnregisterCardModalForm from "./UnregisterCardModalForm";

export default {
  Slider,
  ServicesSteps,
  ContactBanner,
  ServiceItem,
  Industry_Marketing,
  ActionCards,
  ProductPrices,
  PriceTable,
  CollectionProcess,
  SellProductModalForm,
  UnregisterCardModalForm,
};
