import React, { useContext, useEffect, useState } from "react";
import _string from "../../config/localization/strLng";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../provider";
import navigation from "./";
import MicroModal from "micromodal";

function HomeActions(): JSX.Element {
  let history = useHistory();
  const { userInfo, handleLogout } = useContext(UserContext);

  const [isDropActive, setIsDropActive] = useState<boolean>(false);

  useEffect(() => {
    MicroModal.init();
  }, []);

  return userInfo ? (
    <>
      <navigation.components.EditProfileModal />
      <navigation.components.ThemedButton
        action={() => {
          history.push("/profile");
        }}
      >
        <>
          {/* <global.Image
            className="w-6 md:h-8 h-6 md:w-8 rounded-full md:mr-3 flex-shrink-0"
            src={userInfo.avatar}
          /> */}
          <div className="hidden md:flex flex-col items-start">
            <span className="capitalize text-base">{userInfo.name}</span>
          </div>
        </>
      </navigation.components.ThemedButton>
      <div
        className="relative flex flex-col justify-center items-center cursor-pointer bg-primary-800 hover:bg-primary-900 transition duration-100 ease-linear px-3 h-full text-white"
        onClick={() => {
          setIsDropActive(!isDropActive);
        }}
      >
        <i
          className={`md:text-2xl p-2 las la-angle-down transition duration-100 ease-linear transform ${
            isDropActive ? "rotate-180" : ""
          }`}
        ></i>
      </div>
      <div className="relative self-end">
        <div
          style={Object.assign(
            {
              width: 250,
              top: "calc(100% + 0px)",
              right: 5,
              zIndex: 100,
              transformOrigin: "top center",
            },
            isDropActive
              ? {}
              : {
                  transform: "scaleY(0)",
                }
          )}
          className="absolute flex flex-col bg-white rounded-sm shadow-lg py-2 border border-gray-100 border-solid transition duration-200 ease-linear"
        >
          <div className="bg-gray-200 flex justify-end items-center text-gray-700 px-6 py-3 transition duration-100 ease-linear">
            <span className="text-base">{userInfo.code}</span>
            <i className="flex justify-center w-8 ml-2 las la-key"></i>
          </div>

          <button
            onClick={() => {
              MicroModal.show("edit-profile-modal");
            }}
            type="button"
            className="bg-white flex justify-end items-center text-grayMatte hover:bg-green-300 px-6 py-3 transition duration-100 ease-linear"
          >
            <span className="text-base">{_string.ACTIONS.edit_profile}</span>
            <i className="w-8 ml-2 las la-user"></i>
          </button>

          <button
            onClick={() => {
              handleLogout();
            }}
            type="button"
            className="bg-white flex justify-end items-center text-grayMatte hover:bg-green-300 px-6 py-3 transition duration-100 ease-linear"
          >
            <span className="text-base">{_string.ACTIONS.logout}</span>
            <i className="w-8 ml-2 las la-unlock"></i>
          </button>
        </div>
      </div>
    </>
  ) : (
    <> </>
  );
}

export default HomeActions;
