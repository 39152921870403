import React, { useCallback, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../provider";

interface AsideContactProps {
  height?: number;
  position?: "left" | "right";
  autoHide?: boolean;
}

function AsideContact({
  position = "right",
  autoHide = true,
  ...props
}: AsideContactProps): JSX.Element {
  const { cekreziConfig } = useContext(AppContext);
  const ref = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (ref.current && childRef.current) {
      let parentHeight = ref.current.parentElement?.clientHeight;

      let selfHeight = ref.current.clientHeight;

      ref.current.style.width = `${parentHeight}px`;

      if (position === "left") {
        ref.current.style.transform = "rotate(90deg)";
        ref.current.style.transformOrigin = "left top";
        ref.current.style.left = `${selfHeight}px`;

        childRef.current.style.transform = "rotate(-180deg)";
      } else {
        ref.current.style.transform = "rotate(-90deg)";
        ref.current.style.transformOrigin = "right top";
        ref.current.style.right = `${selfHeight}px`;

        childRef.current.style.transform = "rotate(180deg)";
      }
    }
  }, [position]);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <div
      style={
        props.height
          ? {
              height: props.height,
            }
          : {
              height: "100%",
            }
      }
      className={`
      ${autoHide && "hidden lg:block"}
      ${
        position === "left" ? "left-0" : "right-0"
      } absolute bg-primary-800 top-0 w-10 lg:w-16 `}
    >
      <div
        ref={ref}
        style={{ zIndex: 29 }}
        className={`absolute top-0 h-10 lg:h-16 w-full text-2xs lg:text-base text-white font-light`}
      >
        <div
          ref={childRef}
          className="w-full h-full flex justify-center overflow-hidden"
        >
          {cekreziConfig &&
            cekreziConfig.phoneNumber.map((number, index) => {
              return (
                <div key={index} className="flex items-center">
                  {index === 0 && (
                    <div className="flex items-center">
                      <div className="flex items-center justify-center bg-white rounded-full w-5 h-5 mr-2">
                        <i className="las la-phone text-grayMatte" />
                      </div>
                    </div>
                  )}
                  <a
                    href={`tel:${number}`}
                    className="hover:text-primary-100 transition duration-100 ease-linear"
                  >
                    {number}
                  </a>
                  {index !== cekreziConfig.phoneNumber.length - 1 && (
                    <div style={{ width: 1 }} className="h-4 mx-1 lg:mx-3" />
                  )}
                </div>
              );
            })}

          <div className="px-5" />

          {cekreziConfig &&
            cekreziConfig.email.map((email, index) => {
              return (
                <div key={index} className="flex items-center">
                  {index === 0 && (
                    <div className="flex items-center">
                      <div className="flex items-center justify-center bg-white rounded-full w-5 h-5 mr-2">
                        <i className="las la-at text-grayMatte" />
                      </div>
                    </div>
                  )}
                  <a
                    href={`mailto:${email}`}
                    className="hover:text-primary-100 transition duration-100 ease-linear"
                  >
                    {email}
                  </a>
                  {index !== cekreziConfig.email.length - 1 && (
                    <div style={{ width: 1 }} className="h-4 mx-1 lg:mx-3" />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default AsideContact;
