import React from "react";
import _string from "../../config/localization/strLng";
import { GeneralLayout } from "../../layout";

function RegisterScreen() {
  return (
    <GeneralLayout pageName={_string.PAGES.register}>
      <div className={""}></div>
    </GeneralLayout>
  );
}

export default RegisterScreen;
