import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import container from "../container";

function SwitchNavigation() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={container.HomeScreen} />
        <Route exact path="/home" component={container.HomeScreen} />
        <Route exact path="/contact-us" component={container.ContactScreen} />
        <Route exact path="/about-us" component={container.AboutUsScreen} />
        <Route exact path="/services" component={container.ServicesScreen} />
        <Route exact path="/profile" component={container.ProfileScreen} />
      </Switch>
    </BrowserRouter>
  );
}

export default SwitchNavigation;
