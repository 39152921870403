import React, { useContext, useState } from "react";
import { pages } from "./common-routes";
import { useHistory } from "react-router-dom";
import { MenuPages } from "./HomeNavigation";
import { UserContext } from "../../provider";
import _string from "../../config/localization/strLng";

function MobileNavigation(): JSX.Element {
  let history = useHistory();
  const { userInfo } = useContext(UserContext);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  return (
    <div className="outer-container fluid bg-grayMatte">
      <div className="flex lg:hidden relative inner-container justify-start items-center">
        <button
          onClick={() => {
            setIsMenuVisible(!isMenuVisible);
          }}
          className={`relative z-50 h-16 w-16 flex justify-center items-center
        text-white text-2xl hover:bg-green-800 transition duration-100 ease-linear`}
        >
          <i className="las la-bars" />
        </button>

        {/* Mobile Menu */}
        <div
          className={`absolute flex flex-col pt-16 pb-4 justify-center left-0 z-30 top-0 overflow-y-auto
          text-white text-lg bg-grayMatte transition-all duration-100 ease-linear`}
          style={Object.assign(
            { width: "100vw", height: "100vh" },
            isMenuVisible ? { left: "0" } : { left: "-110%" }
          )}
        >
          {isMenuVisible && (
            <div
              className="fixed h-16 w-full bg-grayMatte top-0 left-0 z-60"
              style={{}}
            />
          )}
          {userInfo && (
            <div className="order-2">
              <Button
                label={_string.PAGES.profile}
                page={"/profile"}
                isActive={history.location.pathname === "/profile"}
              />
            </div>
          )}
          {pages.map((page, index) => (
            <div
              key={index}
              className={`${
                page.page === "/" ? "order-1" : `order-${index + 2}`
              }`}
            >
              <Button
                label={page.label}
                page={page.page}
                isActive={history.location.pathname === page.page.split("#")[0]}
              />
            </div>
          ))}
        </div>
        {/* End Mobile Menu */}
      </div>
    </div>
  );
}

export default MobileNavigation;

interface ButtonProps extends MenuPages {
  isActive: boolean;
}

function Button({ label, page, isActive }: ButtonProps) {
  let history = useHistory();
  return (
    <button
      className={`
      ${isActive && "underline"}
      flex justify-start self-center flex-shrink-0 w-full py-5 pl-16 pr-4 mt-2
      hover:bg-primary-900 transition duration-100 ease-linear truncate`}
      onClick={() => {
        history.push(page);
        window.scrollTo(0, 1);
      }}
    >
      <span>{label}</span>
    </button>
  );
}
