import React from "react";
import _string from "../../../config/localization/strLng";
import global from "../../../global";
import { homeInfoConfig_Al } from "../../../config/HomeInfoConfig";
import { useHistory } from "react-router-dom";
import triangle_shape from "../../../assets/img/triangle_shape.svg";

function ContactBanner(): JSX.Element {
  const history = useHistory();

  return (
    <div className="outer-container relative fluid">
      <div style={{ height: 355 }} className="inner-container flex relative">
        <div className="z-10 flex flex-1 flex-col md:flex-row justify-center md:justify-between items-center pt-20 md:pt-0 px-12 md:px-20 lg:px-48">
          <span className="text-white md:text-left text-2xl md:text-3xl font-bold whitespace-pre-line mb-10 md:mb-0 mx-4 md:mx-0">
            {_string.MESSAGES.collaborate_with_out_initiative}
          </span>
          <global.Button
            onClick={() => {
              history.push("/contact");
            }}
          >
            {_string.ACTIONS.contact}
          </global.Button>
        </div>

        <global.Image
          className="absolute left-0 top-0 z-10 w-48 h-48"
          src={triangle_shape}
          alt=""
        />
        {homeInfoConfig_Al && homeInfoConfig_Al.contactBannerImage && (
          <>
            <global.Image
              src={homeInfoConfig_Al.contactBannerImage}
              className="absolute w-full h-full top-0 left-0 object-cover"
            />
            <div className="absolute w-full h-full top-0 left-0 bg-black opacity-50 z-0" />
          </>
        )}
      </div>
    </div>
  );
}

export default ContactBanner;
